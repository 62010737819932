//React element
import React from "react";
import { Field, ErrorMessage } from "formik";

const Texts = ({ name, type, errors, ...rest }) => {
  return (
    <div className="custom-text">
      <Field className="plainText" name={name} type={type} {...rest} />
      {errors[name] ? <div className="form-error">{errors[name]}</div> : ""}
      {/* <ErrorMessage className="form-error" name={name} component="div" /> */}
    </div>
  );
};

export default Texts;
