//React element
import React from "react";
import { Modal } from "react-bootstrap";

//Modal body
import ModalBox from "./settingModalBox";

//Svg
import SvgImage from "../../assets/x-circle.svg";

const AddModal = ({
  show,
  closeHandler,
  modalID,
  modalHead,
  setConfirmAdd,
}) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
      className="popup-modalbox-sure"
    >
      <div className="modalbox-Xbutton-div">
        <img
          src={SvgImage}
          variant="default"
          alt="X"
          size="sm"
          onClick={() => closeHandler()}
        />
      </div>
      <Modal.Header className="modalbox-header-createdit">
        <Modal.Title>{modalHead}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbox-body">
        {ModalBox.AddModalBox(modalID, closeHandler, setConfirmAdd)}
      </Modal.Body>
    </Modal>
  );
};

export default AddModal;
