//React element
import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { VscDiffAdded } from "react-icons/vsc";
import { toast } from "react-toastify";

//Form element
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormikForm from "./formikForm";

//API integration
import authService from "../services/auth-service";
import renderService from "../services/render-service";

//Elements
import Buttons from "./elements/plainButton";

async function onSubmitted(values, caseNaming, closeHandler, setSubmitting) {
  switch (caseNaming) {
    case "position id": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.position,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.username ==
          "The username has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "apparatus": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.itemName,
          values.password,
          values.powerUnit,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        } else if (ex.response.data.message == "The given data was invalid.") {
          toast.configure();
          toast("All created section must be filled", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }

        setSubmitting(false);
      }
      break;
    }
    case "customer": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );

        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          // console.log("success");
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "pic receive": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "follow up by": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "dismantle by-dismantle": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "inspection by-dismantle": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "check by-dismantle": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-dismantle": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "quote by-quotation": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-quotation": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "feedback -quotation": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "prepare by-pretest": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-pretest": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-pretest": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "work confirm by-customer confirmation": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-customer confirmation": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "assembly by-job in progress": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "check by-job in progress": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-job in progress": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "testing by-qc inspection": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "complete by-qc inspection": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-qc inspection": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "prepare by-report": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-report": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "issue by-billing": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        // console.log("Success_others");
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "delivery by-billing": {
      try {
        const response = await authService.updateSetting(
          caseNaming,
          values.username,
          values.password,
          values.id
        );
        if (response) {
          toast.configure();
          toast("Update Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    default: {
      break;
    }
  }
}

const EditModalBox = ({
  innerShow,
  closeHandler,
  caseNaming,
  modalHead,
  viewListData,
}) => {
  const [innerEdit, setInnerEdit] = useState(false);
  const [options, setOptions] = useState({});

  const getOptions = async () => {
    const response = await renderService.optionsListing();
    setOptions(response.data.data);
  };

  useEffect(() => {
    // getOptions().catch((e) => {
    //   console.log(e);
    // });
    if (innerShow) {
      getOptions().catch((e) => {
        // console.log(e);
      });
      setInnerEdit(true);
    }
  }, [innerEdit, innerShow]);

  function showContent(caseNaming) {
    if (viewListData) {
      switch (caseNaming) {
        case "position id": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
            password: Yup.string().required("Required"),
            position: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.username,
                password: viewListData.password,
                position: viewListData.position_id,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row className="modalbox-jobox-row">
                      <Col className="col-5 modalbox-cebox-listName">
                        Username
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-jobox-row">
                      <Col className="col-5 modalbox-cebox-listName">
                        Password
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("password", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-jobox-row">
                      <Col className="col-5 modalbox-cebox-listName">
                        Position
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderSelect("position", options, errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "apparatus": {
          const schema = Yup.object({
            itemName: Yup.string().required("Required"),
            powerUnit: Yup.array().required("Required"),
          });

          let length = [];
          viewListData.power_units.map((item) => {
            length.push(item.name);
          });
          return (
            <Formik
              initialValues={{
                itemName: viewListData.item,
                powerUnit: length,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ isSubmitting, handleReset, values, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row className="modalbox-cebox-row">
                      <Col className="col-5 modalbox-cebox-listName">
                        Item Name:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("itemName", "", errors)}
                      </Col>
                    </Row>
                    <FieldArray name="powerUnit">
                      {({ push, remove }) => (
                        <React.Fragment>
                          {values.powerUnit.map((_, index) => (
                            <Row className="modalbox-jobox-row" key={index}>
                              <Col className="col-5 modalbox-cebox-listName">
                                Power Unit Type {index + 1} :
                              </Col>
                              <Col className="modalbox-cebox-input">
                                {FormikForm.renderPlainText(
                                  `powerUnit[${index}]`,
                                  "",
                                  errors
                                )}
                              </Col>
                            </Row>
                          ))}
                          <Row className="modalbox-jobox-row">
                            <Col className="col-5 modalbox-cebox-listName">
                              Add power unit :
                            </Col>
                            <Col>
                              <VscDiffAdded
                                style={{ color: "#AAA5BE" }}
                                onClick={() => push()}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </FieldArray>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "customer": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "pic receive": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "follow up by": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "dismantle by-dismantle": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "inspection by-dismantle": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "check by-dismantle": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "receive by-dismantle": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{ username: viewListData.name }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "quote by-quotation": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "approve by-quotation": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "feedback -quotation": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "prepare by-pretest": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "approve by-pretest": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "receive by-pretest": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "work confirm by-customer confirmation": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "receive by-customer confirmation": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "assembly by-job in progress": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "check by-job in progress": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "receive by-job in progress": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "testing by-qc inspection": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "complete by-qc inspection": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "receive by-qc inspection": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "prepare by-report": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "approve by-report": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "issue by-billing": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }
        case "delivery by-billing": {
          const schema = Yup.object({
            username: Yup.string().required("Required"),
          });

          return (
            <Formik
              initialValues={{
                username: viewListData.name,
                id: viewListData.id,
              }}
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) => {
                onSubmitted(values, caseNaming, closeHandler, setSubmitting);
              }}
            >
              {({ handleReset, isSubmitting, isValid, errors }) => (
                <Form>
                  <Container>
                    <Row>
                      <Col className="col-5 modalbox-cebox-listName">
                        Username:
                      </Col>
                      <Col className="col-7 modalbox-cebox-input">
                        {FormikForm.renderPlainText("username", "", errors)}
                      </Col>
                    </Row>
                    <Row className="modalbox-cebox-buttonRow">
                      <Col className="modalbox-cebox-button">
                        <Buttons
                          label={"Clear"}
                          onClick={() => {
                            handleReset();
                          }}
                        />
                      </Col>
                      <Col className="modalbox-cebox-button">
                        {FormikForm.renderButton(
                          "Submit",
                          isSubmitting,
                          isValid
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )}
            </Formik>
          );
        }

        default: {
          return <></>;
        }
      }
    }
    // switch (caseNaming) {
    //   case "position id": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //       password: Yup.string().required("Required"),
    //       position: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{
    //           username: viewListData.username,
    //           password: viewListData.password,
    //           position: viewListData.position_id,
    //           id: viewListData.id,
    //         }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row className="modalbox-jobox-row">
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-jobox-row">
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Password
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("password")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-jobox-row">
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Position
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderSelect("position", options)}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "apparatus": {
    //     const schema = Yup.object({
    //       itemName: Yup.string().required("Required"),
    //       powerUnit: Yup.array().required("Required"),
    //     });

    //     let length = [];
    //     viewListData.power_units.map((item) => {
    //       length.push(item.name);
    //     });
    //     return (
    //       <Formik
    //         initialValues={{
    //           itemName: viewListData.item,
    //           powerUnit: length,
    //           id: viewListData.id,
    //         }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ isSubmitting, handleReset, values, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row className="modalbox-cebox-row">
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Item Name:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("itemName")}
    //                 </Col>
    //               </Row>
    //               <FieldArray name="powerUnit">
    //                 {({ push, remove }) => (
    //                   <React.Fragment>
    //                     {values.powerUnit.map((_, index) => (
    //                       <Row className="modalbox-jobox-row" key={index}>
    //                         <Col className="col-5 modalbox-cebox-listName">
    //                           Power Unit Type {index + 1} :
    //                         </Col>
    //                         <Col className="modalbox-cebox-input">
    //                           {FormikForm.renderPlainText(
    //                             `powerUnit[${index}]`
    //                           )}
    //                         </Col>
    //                       </Row>
    //                     ))}
    //                     <Row className="modalbox-jobox-row">
    //                       <Col className="col-5 modalbox-cebox-listName">
    //                         Add power unit :
    //                       </Col>
    //                       <Col>
    //                         <VscDiffAdded
    //                           style={{ color: "#AAA5BE" }}
    //                           onClick={() => push()}
    //                         />
    //                       </Col>
    //                     </Row>
    //                   </React.Fragment>
    //                 )}
    //               </FieldArray>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "customer": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{
    //           username: viewListData.name,
    //           id: viewListData.id,
    //         }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "pic receive": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "follow up by": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "dismantle by-dismantle": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "inspection by-dismantle": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "check by-dismantle": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{
    //           username: viewListData.name,
    //           id: viewListData.id,
    //         }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "receive by-dismantle": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "quote by-quotation": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "approve by-quotation": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "feedback -quotation": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "prepare by-pretest": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "approve by-pretest": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "receive by-pretest": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "work confirm by-customer confirmation": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "receive by-customer confirmation": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "assembly by-job in progress": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "check by-job in progress": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "receive by-job in progress": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "testing by-qc inspection": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "complete by-qc inspection": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "receive by-qc inspection": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "prepare by-report": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "approve by-report": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "issue by-billing": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }
    //   case "delivery by-billing": {
    //     const schema = Yup.object({
    //       username: Yup.string().required("Required"),
    //     });

    //     return (
    //       <Formik
    //         initialValues={{ username: viewListData.name, id: viewListData.id }}
    //         validationSchema={schema}
    //         onSubmit={(values, { setSubmitting }) => {
    //           onSubmitted(values, caseNaming, closeHandler, setSubmitting);
    //         }}
    //       >
    //         {({ handleReset, isSubmitting, isValid }) => (
    //           <Form>
    //             <Container>
    //               <Row>
    //                 <Col className="col-5 modalbox-cebox-listName">
    //                   Username:
    //                 </Col>
    //                 <Col className="col-7 modalbox-cebox-input">
    //                   {FormikForm.renderPlainText("username")}
    //                 </Col>
    //               </Row>
    //               <Row className="modalbox-cebox-buttonRow">
    //                 <Col className="modalbox-cebox-button">
    //                   {FormikForm.renderButton("Submit", isSubmitting, isValid)}
    //                 </Col>
    //                 <Col className="modalbox-cebox-button">
    //                   <Buttons
    //                     label={"Clear"}
    //                     onClick={() => {
    //                       handleReset();
    //                     }}
    //                   />
    //                 </Col>
    //               </Row>
    //             </Container>
    //           </Form>
    //         )}
    //       </Formik>
    //     );
    //   }

    //   default: {
    //     return <></>;
    //   }
    // }
  }

  return (
    <Modal
      show={innerShow}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
      className="popup-modalbox-bg"
    >
      <div className="modalbox-Xbutton-div">
        <Button
          variant="default"
          className="modalbox-Xbutton"
          size="sm"
          onClick={() => closeHandler()}
        >
          X
        </Button>
      </div>
      <Modal.Header className="modalbox-header-createdit">
        <Modal.Title>{modalHead}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbox-body">
        {showContent(caseNaming)}
      </Modal.Body>
    </Modal>
  );
};

export default EditModalBox;
