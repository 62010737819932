//React element
import React from "react";

//Elements folder
import Input from "./elements/input";
import Button from "./elements/button";
import Buttons from "./elements/plainButton";
import Texts from "./elements/plainText";
import Textarea from "./elements/textarea";
import Select from "./elements/select";
import SelectV2 from "./elements/selectV2";
import SaveButton from "./elements/saveButton";

function renderInput(name, label, type = "text", ...rest) {
  return <Input name={name} label={label} type={type} {...rest} />;
}

function renderButton(label, isSubmitting, isValid, ...rest) {
  return (
    <Button
      label={label}
      isSubmitting={isSubmitting}
      isValid={isValid}
      {...rest}
    />
  );
}

function renderPlainButton(label, type, ...rest) {
  return <Buttons label={label} type={type} {...rest} />;
}

function renderPlainText(name, type = "text", errors, ...rest) {
  return <Texts name={name} type={type} errors={errors} {...rest} />;
}

function renderTextarea(name, errors, ...rest) {
  return <Textarea name={name} errors={errors} {...rest} />;
}

function renderSelect(name, options, errors, ...rest) {
  return <Select name={name} options={options} errors={errors} {...rest} />;
}

function renderSelectV2(name, options, errors, ...rest) {
  return <SelectV2 name={name} options={options} errors={errors} {...rest} />;
}

function renderSave(label, value, name, setFieldValue, isSubmitting, ...rest) {
  return (
    <SaveButton
      label={label}
      value={value}
      name={name}
      setFieldValue={setFieldValue}
      isSubmitting={isSubmitting}
      {...rest}
    />
  );
}

export default {
  renderInput,
  renderButton,
  renderPlainButton,
  renderPlainText,
  renderTextarea,
  renderSelect,
  renderSelectV2,
  renderSave,
};
