//React element
import React from "react";

const Signal = ({ value, status }) => {
  console.log("value",value)
  console.log("status",status)
  switch (value) {
    case "complete": {
      return <span className="btn element-button-green" />;
    }
    case "bypass": {
      return <span className="btn element-button-blue" />;
    }
    case "expired": {
      if (status === "Urgent") {
        return <span className="btn element-button-red-danger" />;
      } else {
        return <span className="btn element-button-red-safe" />;
      }
    }
    case "": {
      return <span className="btn element-button-grey" />;
    }
    case null: {
      return <span className="btn element-button-grey" />;
    }
    default: {
      return null;
    }
  }
};

export default Signal;
