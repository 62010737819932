//React element
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import moment from "moment";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

//Elements
import Button from "./elements/plainButton";
import ShowFileImg from "./elements/showFileImg";

//Edit form
import EditTabForm from "./editProcessTabEditForm";

const EditTab = ({
  data,
  name,
  editName,
  activeTab,
  customerID,
  refresh,
  setRefresh,
  refreshPurchaseSave,
  setRefreshPurchaseSave,
  refreshMedia,
  setRefreshMedia,
  refreshPage,
  setRefreshPage,
}) => {
  const [editButton, setEditButton] = useState(false);
  // const [info, setInfo] = useState({});

  useEffect(() => {
    // console.log("Edit tab data", data);
    // if (data) {
    //   setInfo(data.data);
    // }
    setEditButton(false);
  }, [activeTab]);

  const showContent = (info, name) => {
    // console.log("Iam Info", info);

    if (!_.isEmpty(info)) {
      switch (name) {
        case "dismantle": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Dismantle By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.dismantle_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Inspection By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.inspection_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Checked By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.check_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Received By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_name}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        case "quotation": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Quotation No :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.quotation_no}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Quoted By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.quote_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Approved By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.approve_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">
                  Received By Customer:
                </Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_customer}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Feedback :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.feedback_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Attached File 1 :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment[0] ? (
                    <a href={info.attachment[0].url} target="_blank">
                      {/* <img
                        src={info.attachment[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Attached File 2 :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment[1] ? (
                    <a href={info.attachment[1].url} target="_blank">
                      {/* <img
                        src={info.attachment[1].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[1].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment[1].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Attached File 3 :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment[2] ? (
                    <a href={info.attachment[2].url} target="_blank">
                      {/* <img
                        src={info.attachment[2].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[2].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment[2].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        case "pretest": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Prepare By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.prepare_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Approved By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.approve_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Received By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Attached File :</Col>
                <Col className="editprocessTabEdit-col2">
                  {/* {info.attachment[0] ? info.attachment[0].url : ""} */}
                  {info.attachment[0] ? (
                    <a href={info.attachment[0].url} target="_blank">
                      {/* <img
                        src={info.attachment[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <DocViewer
                        documents={info.attachment[0].url}
                        className="uploadfile-preview"
                        pluginRenderers={DocViewerRenderers}
                      /> */}
                      {/* <ShowFileImg data={info.attachment[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        case "customer": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Work Confirm by :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.work_confirm_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Received By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
            </React.Fragment>
          );
        }
        case "purchasing": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Item :</Col>
                <Col className="editprocessTabEdit-col2">
                  {/* {info.purchase_listings} */}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        case "job": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Assembly By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.assembly_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Checked By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.check_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Received By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
            </React.Fragment>
          );
        }
        case "qc": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Testing By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.testing_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Completed By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.complete_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Received By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.receive_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
            </React.Fragment>
          );
        }
        case "report": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Prepare By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.prepare_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Approved By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.approve_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remark :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1-attachment">
                  Attached File Report 1:
                </Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment_1[0] ? (
                    <a href={info.attachment_1[0].url} target="_blank">
                      {/* <img
                        src={info.attachment_1[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_1[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment_1[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1-attachment">
                  Attached File Report 2:
                </Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment_2[0] ? (
                    <a href={info.attachment_2[0].url} target="_blank">
                      {/* <img
                        src={info.attachment_2[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_2[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment_2[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1-attachment">
                  Attached File Report 3:
                </Col>
                <Col className="editprocessTabEdit-col2">
                  {info.attachment_3[0] ? (
                    <a href={info.attachment_3[0].url} target="_blank">
                      {/* <img
                        src={info.attachment_3[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_3[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.attachment_3[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1-attachment">
                  Attached File White Card :
                </Col>
                <Col className="editprocessTabEdit-col2">
                  {info.white_card[0] ? (
                    <a href={info.white_card[0].url} target="_blank">
                      {/* <img
                        src={info.white_card[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.white_card[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {info.white_card[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
        case "billing": {
          return (
            <React.Fragment>
              <Row>
                <Col className="editprocessTabEdit-col1">Date :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.date === ""
                    ? ""
                    :  moment(info.date).format('YYYY-MM-DD')}
                    <br />
                    {info.date === ""
                    ? ""
                    : moment(info.date).format('hh:mm:ss')
                    }
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Issue By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.issue_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Invoice / DN No :</Col>
                <Col className="editprocessTabEdit-col2">{info.invoice_no}</Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Delivery By :</Col>
                <Col className="editprocessTabEdit-col2">
                  {info.delivery_by_name}
                </Col>
              </Row>
              <Row>
                <Col className="editprocessTabEdit-col1">Remarks :</Col>
                <Col className="editprocessTabEdit-col2">{info.remark}</Col>
              </Row>
            </React.Fragment>
          );
        }

        default: {
          return <React.Fragment />;
        }
      }
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {editButton ? (
        <EditTabForm
          value={data}
          name={name}
          editName={editName}
          customerID={customerID}
          selectValue={data.data}
          refresh={refresh}
          setRefresh={setRefresh}
          editButton={editButton}
          setEditButton={setEditButton}
          refreshPurchaseSave={refreshPurchaseSave}
          setRefreshPurchaseSave={setRefreshPurchaseSave}
          refreshMedia={refreshMedia}
          setRefreshMedia={setRefreshMedia}
          refreshPage={refreshPage}
          setRefreshPage={setRefreshPage}
        />
      ) : (
        <Container className="editprocessTabcontent-container">
          <Row className="editprocessTabEdit-row1">
            {data.editable === true ? (
              <Button
                label="Edit"
                onClick={() => {
                  {
                    setEditButton(true);
                  }
                }}
              />
            ) : (
              ""
            )}
          </Row>
          {showContent(data.data, name)}
        </Container>
      )}
    </React.Fragment>
  );
};

export default EditTab;
