//React element
import { useState } from "react";

//Custom element
import http from "./http-service";
import config from "../../config.json";
import auth from "./auth-service";
import { identity } from "lodash";

async function jobListing(search = null, branchNaming = null, start = 0) {
  http.setToken(auth.getToken());
  var url = "";
  // console.log("jobListing render API", branchNaming);
  if (
    search != "" &&
    search != null &&
    (branchNaming == "" || branchNaming == null)
  ) {
    url = `/job?status=active&start=${start}&length=15&search=` + search;
  } else if (
    branchNaming != "" &&
    branchNaming != null &&
    (search == "" || search == null)
  ) {
    url = `/job?status=active&start=${start}&length=15&branch=` + branchNaming;
  } else if (
    branchNaming != "" &&
    branchNaming != null &&
    search != "" &&
    search != null
  ) {
    url =
      `/job?status=active&start=${start}&length=15&branch=` +
      branchNaming +
      "&search=" +
      search;
  } else if (
    search == "" ||
    search == null ||
    branchNaming == "" ||
    branchNaming == null
  ) {
    url = `/job?status=active&start=${start}&length=15`;
  }
  const response = await http.get(config.apiPoint + url);

  return response.data;
}

async function nextJobListing(quickSearch, currentPage) {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint +
      `/job?status=active&start=${currentPage}&length=15&search=${quickSearch}`
  );

  return response.data;
}

async function totalJobListing() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/job");

  return response.data;
}

async function jobInfo(custID) {
  http.setToken(auth.getToken());
  if (custID) {
    const response = await http.get(config.apiPoint + "/job/" + custID);
    return response.data;
  } else {
    const response = await http.get(config.apiPoint + "/job/");
    return response.data;
  }
}

async function mainEdit(custID) {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/job/" + custID);
  return response.data;
}

async function viewListing(modalType, searchValue) {
  http.setToken(auth.getToken());
  if (searchValue === undefined) {
    const response = await http.get(
      config.apiPoint + `/setting?type=${modalType}`
    );
    return response.data;
  } else {
    const response = await http.get(
      config.apiPoint + `/setting?type=${modalType}&search=${searchValue}`
    );
    return response.data;
  }
}

async function headerInfo() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/profile");
  return response.data;
}

async function archiveListing(search = null, branchNaming = null, start = 0) {
  http.setToken(auth.getToken());

  var url = "";

  // if (search != "" && search != null) {
  //   url = "/job?status=complete&start=0&length=15&search=" + search;
  // } else {
  //   url = "/job?status=complete&start=0&length=15";
  // }
  if (
    search != "" &&
    search != null &&
    (branchNaming == "" || branchNaming == null)
  ) {
    url = `/job?status=complete&start=${start}&length=15&search=` + search;
  } else if (
    branchNaming != "" &&
    branchNaming != null &&
    (search == "" || search == null)
  ) {
    url =
      `/job?status=complete&start=${start}&length=15&branch=` + branchNaming;
  } else if (
    branchNaming != "" &&
    branchNaming != null &&
    search != "" &&
    search != null
  ) {
    url =
      `/job?status=complete&start=${start}&length=15&branch=` +
      branchNaming +
      "&search=" +
      search;
  } else if (
    search == "" ||
    search == null ||
    branchNaming == "" ||
    branchNaming == null
  ) {
    url = `/job?status=complete&start=${start}&length=15`;
  }
  const response = await http.get(config.apiPoint + url);

  return response.data;
}

async function nextJobListing2(quickSearch, currentPage) {
  http.setToken(auth.getToken());
  console.log("currentPage", currentPage);
  console.log("quickSearch", quickSearch);
  const response = await http.get(
    config.apiPoint +
      `/job?status=complete&start=${currentPage}&length=15&search=${quickSearch}`
  );

  return response.data;
}

async function totalJobListing2() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/job?status=complete");

  return response.data;
}

async function optionsListing() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/setting?type=position");

  return response.data;
}

async function nextJobNo() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/profile");

  return response.data;
}

async function optionsCustomer() {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/active_setting?type=customer"
  );

  return response.data;
}

async function optionsApparatus() {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/active_setting?type=apparatus"
  );

  return response.data;
}

async function optionsPowerUnit(apparatusID) {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/apparatus_type/" + apparatusID
  );

  return response.data;
}

async function optionsPICreceive() {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/active_setting?type=pic receive"
  );

  return response.data;
}

async function optionsFollow() {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/active_setting?type=follow up by"
  );

  return response.data;
}

async function editJobInfo(jobType) {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/active_setting?type=" + jobType
  );

  return response.data;
}

async function purchaseList(customID) {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/job/" + customID);
  return response.data;
}

async function settingListContent() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/settingList");

  return response.data;
}

async function listAllID(listLength, pageName) {
  http.setToken(auth.getToken());

  // console.log("render listLength", listLength);

  if (pageName === "main") {
    const response = await http.get(
      config.apiPoint + `/job?status=active&start=0&length=${listLength}`
    );

    return response.data;
  } else if (pageName === "archive") {
    const response = await http.get(
      config.apiPoint + `/job?status=complete&start=0&length=${listLength}`
    );

    return response.data;
  }

  // console.log("render allID response", response.data);
}

// async function accountStatus() {
//   http.setToken(auth.getToken());

//   const response = await http.get(
//     config.apiPoint + "/active_setting?type=position id"
//   );

//   console.log("Render account status", response.data);

//   return response.data;
// }

async function quickSearchList(quickSearch) {
  http.setToken(auth.getToken());

  const response = await http.get(
    config.apiPoint + "/job?status=active&search=" + quickSearch
  );

  return response.data;
}

async function getBranchs() {
  http.setToken(auth.getToken());

  const response = await http.get(config.apiPoint + "/branch");

  return response.data;
}

export default {
  jobListing,
  nextJobListing,
  totalJobListing,
  jobInfo,
  viewListing,
  archiveListing,
  nextJobListing2,
  totalJobListing2,
  optionsListing,
  nextJobNo,
  optionsCustomer,
  optionsApparatus,
  optionsPowerUnit,
  optionsFollow,
  optionsPICreceive,
  editJobInfo,
  purchaseList,
  headerInfo,
  mainEdit,
  settingListContent,
  listAllID,
  // accountStatus,
  quickSearchList,
  getBranchs,
};
