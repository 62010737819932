//React element
import React from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

//Elements
import BodyProcess from "./elements/titleText";

const Process = () => {
  return (
    <Container className="title-padding body-titleRow-text">
      <Row style={{ alignItems: "center" }}>
        <Col className="title-padding">
          <BodyProcess title="Dismantle" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Quotation" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Pretest" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Customer Confirm" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Purchasing" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Job In Progress" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="QC Inspection" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Report" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Billing" />
        </Col>
        <Col className="title-padding">
          <BodyProcess title="Delivery" />
        </Col>
      </Row>
    </Container>

    // <Table className="title-process">
    //   <tbody>
    //     <tr>
    //       <td>Dismantle</td>
    //       <td></td>
    //       <td>Pretest</td>
    //       <td></td>
    //       <td>Purchasing</td>
    //       <td></td>
    //       <td>QC Inspection</td>
    //       <td></td>
    //       <td>Billing</td>
    //       <td></td>
    //     </tr>
    //     <tr>
    //       <td></td>
    //       <td>Quotation</td>
    //       <td></td>
    //       <td style={{ width: "11.5em" }}>Customer Confirmation</td>
    //       <td></td>
    //       <td>Job In Progress</td>
    //       <td></td>
    //       <td>Report</td>
    //       <td></td>
    //       <td>Delivery</td>
    //       <td></td>
    //     </tr>
    //   </tbody>
    // </Table>
  );
};

export default Process;
