import React from "react";

const SaveButton = ({
  label,
  value,
  name,
  setFieldValue,
  isSubmitting,
  ...rest
}) => {
  return (
    <span className="custom-button">
      <button
        type="submit"
        className="btn"
        value={value}
        name={name}
        onClick={() => {
          setFieldValue(name, value);
        }}
        {...rest}
      >
        {isSubmitting ? "Please wait..." : label}
      </button>
    </span>
  );
};

export default SaveButton;
