//React element
import React, { useState } from "react";
import { Container } from "react-bootstrap";

//Header
import Header from "./../common/header";
import Login from "./login";

//Protection
import ProtectedRoute from "./../common/protectedRoute";

const styles = {
  pageBackground: {
    padding: 0,
    // backgroundColor: "#18191A",
    backgroundColor: "#2A2B2C",
    height: "100vh",
    overflow: "scroll",
  },
};

const Screen = ({ header, component: Component, publicAuthorize }) => {
  const [exportData,setExportData] = useState([])
  const [quickSearch, setQuickSearch] = useState("");
  const [branchNaming, setBranchNaming] = useState("");

  // console.log("exportData",exportData)
  return (
    <Container fluid style={styles.pageBackground}>
      {!publicAuthorize ? <ProtectedRoute></ProtectedRoute> : ""}
      <Header
        {...header}
        // selectAll={selectAll}
        // exportAll={exportAll}
        // exportID={exportID}
        exportData={exportData}
        setExportData={setExportData}
        setQuickSearch={setQuickSearch}
        setBranchNaming={setBranchNaming}
      />
      <Component
      // exportData={exportData}
      setExportData={setExportData}
        // setSelectAll={setSelectAll}
        // setExportAll={setExportAll}
        // setExportID={setExportID}
        quickSearch={quickSearch}
        branchNaming={branchNaming}
      />
    </Container>
  );
};

export default Screen;
