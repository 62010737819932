//React element
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Elements
import Button from "./../common/elements/plainButton";
import ShowFileImg from "./elements/showFileImg";

//Edit form
import EditForm from "./editProcessTabPurchaseEdit";

//API integration
import renderService from "../services/render-service";

const PurchaseTab = ({ customerID, refresh, refreshPurchaseSave }) => {
  const [show, setShow] = useState(false);
  const [purchaseInfo, setPurchaseInfo] = useState({});
  const [deleteID, setDeleteID] = useState({});
  const [initValue, setInitValue] = useState({});
  const [refreshPurchaseList, setRefreshPurchaseList] = useState(false);
  const [refreshMedia, setRefreshMedia] = useState(false);

  const customClose = () => {
    setShow(false);
  };

  const getPurchaseInfo = async () => {
    const response = await renderService.purchaseList(customerID);
    setPurchaseInfo(response.data.purchasing.data.purchase_listings);
  };

  // console.log("purchasing tab useEffect", purchaseList);

  useEffect(() => {
    getPurchaseInfo().catch((e) => {
      // console.log(e);
    });
  }, [refresh, refreshPurchaseList, refreshPurchaseSave, refreshMedia]);

  return (
    <Container className="editprocessTabcontent-container">
      <EditForm
        show={show}
        closeHandler={customClose}
        customerID={customerID}
        deleteID={deleteID}
        initValue={initValue}
        refreshPurchaseList={refreshPurchaseList}
        setRefreshPurchaseList={setRefreshPurchaseList}
        refreshMedia={refreshMedia}
        setRefreshMedia={setRefreshMedia}
      />

      <Row className="editprocessTabPurchase-row1">
        <Col className="editprocessTabPurchase-col">Edit</Col>
        <Col className="editprocessTabPurchase-col">Po No</Col>
        <Col className="editprocessTabPurchase-col">Item Name</Col>
        <Col className="editprocessTabPurchase-col">Quantity</Col>
        <Col className="editprocessTabPurchase-col">Remark</Col>
        <Col className="editprocessTabPurchase-col">PDF File Attachment</Col>
      </Row>
      {purchaseInfo.length > 0 &&
        purchaseInfo.map((listing, index) => {
          // console.log("purchase listing", listing);
          return (
            <div key={index}>
              <Row className="editprocessTabPurchase-row2">
                <Col className="editprocessTabPurchase-col">
                  <Button
                    label="Edit"
                    onClick={() => {
                      setShow(true);
                      setDeleteID(listing.id);
                      setInitValue(listing);
                    }}
                  />
                </Col>
                <Col className="editprocessTabPurchase-col">
                  {listing.po_no}
                </Col>
                <Col className="editprocessTabPurchase-col">
                  {listing.item_name}
                </Col>
                <Col className="editprocessTabPurchase-col">
                  {listing.quantity}
                </Col>
                <Col className="editprocessTabPurchase-col">
                  {listing.remark}
                </Col>
                <Col className="editprocessTabPurchase-col">
                  {/* {listing.mainMediaUrl} */}
                  {listing.attachment[0] ? (
                    <a href={listing.attachment[0].url} target="_blank">
                      {/* <img
                        src={listing.attachment[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={listing.attachment[0].url} /> */}
                      <div style={{ wordBreak: "break-word" }}>
                        {listing.attachment[0].name}
                      </div>
                    </a>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          );
        })}
    </Container>
  );
};

export default PurchaseTab;
