//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";

//Logo
import logo from "../../assets/logoV2.png";

//Header element
import HeadName from "./elements/titleText";
import HeadButton from "./../common/elements/plainButton";
import Loader from "./popup/loader";

//Common
import AddNewJob from "./addNewJob";
import ChangePassword from "./popup/changePassword";

//API
import renderService from "../services/render-service";
import authService from "../services/auth-service";

const logoutBox = () => {
  return (
    <Container>
      {/* <Card style={{ width: "5em" }}> */}
      {/* <ListGroup>
          {console.log("logoutBox test")}
          <ListGroupItem>Change Password</ListGroupItem>
          <ListGroupItem>Log out</ListGroupItem>
        </ListGroup> */}
      {/* <Card.Body>
          <Card.Text>Change Password</Card.Text>
          <Card.Text>Log out</Card.Text>
          {console.log("logoutBox test")}
        </Card.Body>
      </Card> */}
    </Container>
  );
};

const Header = ({
  header,
  profileImage,
  profileTab,
  userName,
  userPosition,
  pageName,
  pageNaming,
  companyNumber,
  companyStatus,
  profileFunctionQS,
  profileFunctionA,
  profileFunctionE,
  profileFunctionS,
  exportData,
  setExportData,
  setQuickSearch,
  setBranchNaming,
}) => {
  const [addNewJob, setAddNewJob] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [openState, setOpenState] = useState(false);
  const [searchInfo, setSearchInfo] = useState("");
  const [nextJobNo, setNextJobNo] = useState({});
  //Test to get User Position
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [pageButton, setPageButton] = useState(location.pathname);
  const position = localStorage.getItem("position");
  const [showLoader, setShowLoader] = useState(false);
  const [branchName, setBranchName] = useState("");
  const [superBranchs, setSuperBranchs] = useState([]);

  const getListingStatus = async () => {
    const response = await renderService.headerInfo();
    setUserData(response.data);
    setNextJobNo(response.data.next_job_no);
  };

  const getNextJobNo = async () => {
    const response = await renderService.nextJobNo();
    setNextJobNo(response.data.next_job_no);
  };

  const closeAddNewJob = () => {
    setAddNewJob(false);
  };

  const closeChangePwd = () => {
    setChangePassword(false);
  };

  const handleChange = (event) => {
    setSearchInfo(event.target.value);
  };

  const handleSearch = () => {
    setQuickSearch(searchInfo);
  };

  // console.log("exportData",exportData)
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const getSuperBranchs = async () => {
    const response = await renderService.getBranchs();

    setSuperBranchs(response.data);
  };

  async function exportingData() {
    // setExportData([])
    console.log("aaa ", exportData);
    if (_.isEqual(exportData, "all")) {
      try {
        const response = await authService.exportCSV(exportData);
        if (response) {
          if (response.data.data === "") {
            setShowLoader(false);
            toast.configure();
            toast("Excel will send to your email once ready.", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
          } else {
            setShowLoader(false);
            window.open(response.data.data);
          }
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast("Download Error", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setShowLoader(false);
      }
    } else {
      try {
        const response = await authService.exportCSV(exportData);

        if (response) {
          if (response.data.data === "") {
            setShowLoader(false);
            toast.configure();
            toast("Excel will send to your email once ready.", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
          } else {
            setShowLoader(false);
            window.open(response.data.data);
          }
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast("Download Error", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setShowLoader(false);
      }
    }
  }

  useEffect(() => {
    getListingStatus().catch((e) => {
      // console.log(e);
    });
    getSuperBranchs().catch((e) => {
      // console.log(e);
    });
  }, []);

  useEffect(() => {
    setPageButton(location.pathname);
  }, [location.pathname, exportData]);

  function branchNaming(e) {
    setBranchName(e.target.value);
    setBranchNaming(e.target.value);
  }

  if (header) {
    return (
      <Container fluid className="header-main">
        <AddNewJob
          show={addNewJob}
          closeHandler={closeAddNewJob}
          jobNo={nextJobNo}
        />
        <ChangePassword show={changePassword} closeHandler={closeChangePwd} />
        <Loader showLoad={showLoader} />

        <Row>
          <Col style={{ display: "contents" }}>
            {profileImage ? (
              <Image src={logo} className="header-profilePic" />
            ) : (
              ""
            )}
            {profileTab ? (
              pageButton === "/main" ? (
                <React.Fragment>
                  <span className={"header-tabButtonActive"}>
                    <HeadButton
                      label={"Main Page"}
                      onClick={() => {
                        navigate("/main");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Archives Page"}
                      onClick={() => {
                        navigate("/archives");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    {/* <HeadButton
                      label={"Setting Page"}
                      onClick={() => {
                        navigate("/setting");
                        setSearchInfo("");
                        setPageButton(location.pathname);
                      }}
                    /> */}
                    {position === "branch admin" ||
                    position === "workshop" ||
                    position === "admin" ||
                    position === "report" ||
                    position === "account" ? (
                      <HeadButton
                        label={"Setting Page"}
                        onClick={() => {
                          navigate("/setting");
                          setSearchInfo("");
                          setQuickSearch("");
                          setPageButton(location.pathname);
                          if (localStorage.getItem("prevPath") !== "/edit") {
                            localStorage.removeItem("prevPath");
                            localStorage.removeItem("prevActivePage");
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </React.Fragment>
              ) : pageButton === "/archives" ? (
                <React.Fragment>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Main Page"}
                      onClick={() => {
                        navigate("/main");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButtonActive"}>
                    <HeadButton
                      label={"Archives Page"}
                      onClick={() => {
                        navigate("/archives");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    {position === "branch admin" ||
                    position === "workshop" ||
                    position === "admin" ||
                    position === "report" ||
                    position === "account" ? (
                      <HeadButton
                        label={"Setting Page"}
                        onClick={() => {
                          navigate("/setting");
                          setSearchInfo("");
                          setQuickSearch("");
                          setPageButton(location.pathname);
                          if (localStorage.getItem("prevPath") !== "/edit") {
                            localStorage.removeItem("prevPath");
                            localStorage.removeItem("prevActivePage");
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </React.Fragment>
              ) : pageButton === "/setting" ? (
                <React.Fragment>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Main Page"}
                      onClick={() => {
                        navigate("/main");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Archives Page"}
                      onClick={() => {
                        navigate("/archives");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton(location.pathname);
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButtonActive"}>
                    {position === "branch admin" ||
                    position === "workshop" ||
                    position === "admin" ||
                    position === "report" ||
                    position === "account" ? (
                      <HeadButton
                        label={"Setting Page"}
                        onClick={() => {
                          navigate("/setting");
                          setSearchInfo("");
                          setQuickSearch("");
                          setPageButton(location.pathname);
                          if (localStorage.getItem("prevPath") !== "/edit") {
                            localStorage.removeItem("prevPath");
                            localStorage.removeItem("prevActivePage");
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </React.Fragment>
              ) : pageButton === "/edit" ? (
                <React.Fragment>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Main Page"}
                      onClick={() => {
                        navigate("/main");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton("/main");
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    <HeadButton
                      label={"Archives Page"}
                      onClick={() => {
                        navigate("/archives");
                        setSearchInfo("");
                        setQuickSearch("");
                        setPageButton("archive");
                        if (localStorage.getItem("prevPath") !== "/edit") {
                          localStorage.removeItem("prevPath");
                          localStorage.removeItem("prevActivePage");
                        }
                      }}
                    />
                  </span>
                  <span className={"header-tabButton"}>
                    {position === "branch admin" ||
                    position === "workshop" ||
                    position === "admin" ||
                    position === "report" ||
                    position === "account" ? (
                      <HeadButton
                        label={"Setting Page"}
                        onClick={() => {
                          navigate("/setting");
                          setSearchInfo("");
                          setQuickSearch("");
                          setPageButton("/setting");
                          if (localStorage.getItem("prevPath") !== "/edit") {
                            localStorage.removeItem("prevPath");
                            localStorage.removeItem("prevActivePage");
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Col>
          <Col className="textAlign-end header-userInfo-colPadding">
            <div className="header-userInfo">
              <button
                className="toggle"
                onClick={() => setOpenState(!openState)}
              >
                {userData.username}
              </button>
            </div>
            <div className="header-userInfo">
              <button
                className="toggle2"
                onClick={() => setOpenState(!openState)}
              >
                {userData.position}
              </button>
            </div>
            {openState && (
              <p className="logout">
                {position === "branch admin" || position === "super admin" ? (
                  <li
                    className="custom-li"
                    onClick={() => {
                      setChangePassword(true);
                    }}
                  >
                    Change Password
                  </li>
                ) : (
                  ""
                )}
                <li
                  style={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}
                  onClick={() => logout()}
                >
                  Log out
                </li>
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="header-companyInfo1">
              {pageNaming === "Main Page" ? (
                <HeadName
                  title={userData.header ? userData.header[0].name : ""}
                />
              ) : pageNaming === "Archives Page" ? (
                <HeadName
                  title={userData.header ? userData.header[1].name : ""}
                />
              ) : pageNaming === "Setting Page" ? (
                <HeadName
                  title={userData.header ? userData.header[2].name : ""}
                />
              ) : pageNaming === "Edit Page" ? (
                <HeadName title="Yun Loong System" />
              ) : (
                ""
              )}
            </div>
            <div className="header-companyInfo2">
              {pageNaming === "Main Page" ? (
                <HeadName
                  title={userData.header ? userData.header[0].text : ""}
                />
              ) : pageNaming === "Archives Page" ? (
                <HeadName
                  title={userData.header ? userData.header[1].text : ""}
                />
              ) : pageNaming === "Setting Page" ? (
                <HeadName
                  title={userData.header ? userData.header[2].text : ""}
                />
              ) : pageNaming === "Edit Page" ? (
                <HeadName title="Job Info" />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col className="textAlign-end header-funcButton-paddingTop">
            {/* Additional function for super admin */}
            {position === "super admin" ? (
              <select
                value={branchName}
                onChange={(e) => {
                  branchNaming(e);
                }}
                className="header-selection"
              >
                <option value="">Please Select-</option>
                {!_.isEmpty(superBranchs)
                  ? superBranchs.length > 0 &&
                    superBranchs.map((listing) => {
                      return (
                        <option key={listing.id} value={listing.name}>
                          {listing.name}
                        </option>
                      );
                    })
                  : ""}
              </select>
            ) : (
              ""
            )}
            {/*  */}
            {profileFunctionQS ? (
              <span className="header-funcButton-qsAlign">
                <input
                  className="header-funcInput-qs"
                  placeholder="Quick Search"
                  value={searchInfo}
                  onChange={handleChange}
                />
                <Button
                  className="header-funcButton-qs"
                  variant="outline-primary"
                  onClick={() => handleSearch()}
                >
                  Enter
                </Button>
              </span>
            ) : (
              ""
            )}
            {profileFunctionA ? (
              <span className="header-funcButton-add">
                <HeadButton
                  label={"+ Add New"}
                  onClick={() => {
                    setAddNewJob(true);
                  }}
                />
              </span>
            ) : (
              ""
            )}

            {profileFunctionE ? (
              position != "purchasing" ? (
                <span className="header-funcButton">
                  <HeadButton
                    label={"Export"}
                    onClick={() => {
                      exportingData();
                      setShowLoader(true);
                    }}
                  />
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {/* {profileFunctionS ? (
              <span className="header-funcButton">
                <HeadButton label={"Print"} />
              </span>
            ) : (
              ""
            )} */}
          </Col>
        </Row>
      </Container>
    );
  } else {
    return "";
  }
};

export default Header;
