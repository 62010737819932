//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { VscDiffAdded } from "react-icons/vsc";
import { toast } from "react-toastify";
import moment from "moment";

//Form element
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormikForm from "./formikForm";

//Elements
import Button from "./elements/plainButton";
import SvgGear from "./../../assets/gear.svg";

//API integration
import authService from "../services/auth-service";
import renderService from "../services/render-service";
import axios from "axios";

//Edit button modalbox
import EditModalBox from "./settingEditModalBox";

async function onSubmit(
  values,
  caseName,
  closeHandler,
  setConfirmAdd,
  setSubmitting
) {
  switch (caseName) {
    case "position id": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username,
          values.password,
          values.position
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.username ==
          "The username has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "apparatus": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.itemName,
          values.powerUnit,
          values.addNewPU
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the item name is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }

        setSubmitting(false);
      }
      break;
    }
    case "customer": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
          // console.log("Customer Response caseName is", caseName)
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }

        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "pic receive": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "follow up by": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "dismantle by-dismantle": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "inspection by-dismantle": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "check by-dismantle": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username

          // console.log("333")
        );
        // console.log("Success123");
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-dismantle": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "quote by-quotation": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-quotation": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "feedback -quotation": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "prepare by-pretest": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-pretest": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-pretest": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "work confirm by-customer confirmation": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-customer confirmation": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "assembly by-job in progress": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "check by-job in progress": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-job in progress": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "testing by-qc inspection": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "complete by-qc inspection": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "receive by-qc inspection": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "prepare by-report": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "approve by-report": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "issue by-billing": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    case "delivery by-billing": {
      try {
        const response = await authService.createSetting(
          caseName,
          values.username
        );
        if (response) {
          toast.configure();
          toast("Create Successfully!", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          closeHandler();
          setConfirmAdd(Math.random());
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
        }
        if (
          ex.response.data.errors.name == "The name has already been taken."
        ) {
          toast.configure();
          toast("Sorry, the username is taken.", {
            type: "error",
            position: "top-center",
            cssClass: "backtoask",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
      break;
    }
    default: {
      break;
    }
  }
}

function AddModalBox(caseName, closeHandler, setConfirmAdd) {
  switch (caseName) {
    case "position id": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
        password: Yup.string().required("Required"),
        position: Yup.string().required("Required"),
      });

      const options = [
        { label: "Please Select -", id: 0 },
        { label: "Admin", id: 3 },
        { label: "Workshop", id: 4 },
        { label: "Account", id: 5 },
        { label: "Sales", id: 6 },
        { label: "ISO", id: 7 },
        { label: "Purchasing", id: 8 },
        { label: "Report", id: 11 },
      ];

      return (
        <Formik
          initialValues={{ username: "", password: "", position: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Password :
                  </Col>
                  <Col className="modalbox-cebox-input">
                    {FormikForm.renderPlainText(
                      "password",
                      "Password",
                      errors,
                      "password"
                    )}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Position :
                  </Col>
                  <Col className="modalbox-cebox-input">
                    {FormikForm.renderSelect("position", options, errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "apparatus": {
      const schema = Yup.object({
        itemName: Yup.string().required("Required"),
        powerUnit: Yup.array().required("Required"),
      });

      return (
        <Formik
          initialValues={{
            itemName: "",
            powerUnit: [""],
          }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, values, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Item Name :{" "}
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("itemName", "", errors)}
                  </Col>
                </Row>
                <FieldArray name="powerUnit">
                  {({ push }) => (
                    <React.Fragment>
                      {values.powerUnit.map((_, index) => (
                        <Row className="modalbox-jobox-row">
                          <Col className="col-5 modalbox-cebox-listName">
                            Power Unit Type {index + 1} :
                          </Col>
                          <Col className="modalbox-cebox-input">
                            {FormikForm.renderPlainText(
                              `powerUnit[${index}]`,
                              "",
                              errors
                            )}
                          </Col>
                        </Row>
                      ))}
                      <Row className="modalbox-jobox-row">
                        <Col className="col-5 modalbox-cebox-listName">
                          Add power unit :
                        </Col>
                        <Col>
                          <VscDiffAdded
                            style={{ color: "#AAA5BE" }}
                            onClick={() => push([""])}
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </FieldArray>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "customer": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">Company :</Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "pic receive": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "follow up by": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "dismantle by-dismantle": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "inspection by-dismantle": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "check by-dismantle": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "receive by-dismantle": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "quote by-quotation": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "approve by-quotation": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "feedback -quotation": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "prepare by-pretest": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "approve by-pretest": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "receive by-pretest": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "work confirm by-customer confirmation": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "receive by-customer confirmation": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "assembly by-job in progress": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "check by-job in progress": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "receive by-job in progress": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "testing by-qc inspection": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "complete by-qc inspection": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "receive by-qc inspection": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "prepare by-report": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "approve by-report": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "issue by-billing": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    case "delivery by-billing": {
      const schema = Yup.object({
        username: Yup.string().required("Required"),
      });

      return (
        <Formik
          initialValues={{ username: "" }}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(
              values,
              caseName,
              closeHandler,
              setConfirmAdd,
              setSubmitting
            );
          }}
        >
          {({ handleReset, isSubmitting, isValid, errors }) => (
            <Form>
              <Container>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Username :
                  </Col>
                  <Col className="col-7 modalbox-cebox-input">
                    {FormikForm.renderPlainText("username", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                  <Col className="modalbox-cebox-button">
                    <Button
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      );
    }
    default: {
      return <></>;
    }
  }
}

function ViewModalBox(caseName, modalHead, confirmAdd,searchValue) {
  const [show, setShow] = useState(false);
  const [naming, setNaming] = useState("");
  const [editID, setEditID] = useState("");
  const [viewListHeader, setViewListHeader] = useState({});
  const [viewListData, setViewListData] = useState({});
  const [showEmpty, setShowEmpty] = useState(false);

  // console.log("ddd",searchValue)
  const getViewList = async () => {
    const response = await renderService.viewListing(caseName,searchValue);

    if (response) {
      setViewListHeader(response.data.header);
      setViewListData(response.data.data);
      if (response.data.data.length === 0) {
        setShowEmpty(true);
      }
    } else {
      setShowEmpty(true);
    }
  };

  useEffect(() => {
    setViewListData({});

    if (caseName !== "") {
      getViewList().catch((e) => {
        // console.log(e);
      });
    }
  }, [caseName, confirmAdd,searchValue]);

  const customClose = async () => {
    setShow(false);
    getViewList();
  };

  //Delete Data (label="Delete")
  // const deleteData = (id) => {
  //   const res = axios.delete(
  //     `https://ylsb.ax-tech.co/api/apparatus_type/${id}`
  //   );
  //   if (res) {
  //     getViewList();
  //     toast.configure();
  //     toast("Delete Item Successfully!", {
  //       type: "success",
  //       position: "top-center",
  //       autoClose: 1000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "dark",
  //       draggableDirection: "x",
  //     });
  //     window.location.reload();
  //   }
  // };
  async function deleteData(id) {
    const response = await authService.deleteApparatus(id);

    // console.log("Delete apparatus response", response);

    if (response) {
      getViewList();
      toast.configure();
      toast("Delete Item Successfully!", {
        type: "success",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        draggableDirection: "x",
      });
      // window.location.reload();
    } else {
      toast.configure();
      toast("Fail to delete item", {
        type: "error",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        draggableDirection: "x",
      });
    }
  }

  async function changeItemStatus(listing, itemStatus) {
    switch (caseName) {
      case "position id": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.username,
            listing.password,
            listing.position_id,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "apparatus": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.itemName,
            listing.password,
            listing.powerUnit,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "customer": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );

          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "pic receive": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "follow up by": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "dismantle by-dismantle": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "inspection by-dismantle": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "check by-dismantle": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "receive by-dismantle": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "quote by-quotation": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "approve by-quotation": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "feedback -quotation": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "prepare by-pretest": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "approve by-pretest": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "receive by-pretest": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "work confirm by-customer confirmation": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "receive by-customer confirmation": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "assembly by-job in progress": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "check by-job in progress": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "receive by-job in progress": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "testing by-qc inspection": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "complete by-qc inspection": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "receive by-qc inspection": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "prepare by-report": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "approve by-report": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "issue by-billing": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          // console.log("Success_others");
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      case "delivery by-billing": {
        try {
          const response = await authService.updateSettingViewStatus(
            caseName,
            listing.name,
            listing.password,
            listing.id,
            itemStatus
          );
          if (response) {
            toast.configure();
            toast("Update Successfully!", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "dark",
              draggableDirection: "x",
            });
            getViewList();
          }
        } catch (ex) {
          if (ex && Object.keys(ex).length > 0) {
            const error = ex.response.values;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }
  switch (caseName) {
    case "position id": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            modalHead={modalHead}
            viewListData={viewListData[editID]}
            defaultValue={viewListData.position_id}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center col-4">Action</Col>
          </Row>
          {
            viewListData.length > 0 ? (
              viewListData.length > 0 &&
              viewListData.map((listing, index) => {
                return (
                  <Row className="modalbox-cebox-content" key={listing.id}>
                    <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                    <Col>{listing.position}</Col>
                    <Col>{listing.username}</Col>
                    <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                      <Col style={{ padding: "0em 1em" }}>
                        <Button
                          label="Edit"
                          onClick={() => {
                            setShow(true);
                            setNaming("position id");
                            setEditID(index);
                          }}
                        />
                      </Col>
                      <Col style={{ padding: "0em 1em" }}>
                        {listing.status === "active" ? (
                          <Button
                            className="activeButton"
                            label={listing.status}
                            onClick={() => {
                              changeItemStatus(listing, "inactive");
                            }}
                          />
                        ) : listing.status === "inactive" ? (
                          <Button
                            className="inactiveButton"
                            label={listing.status}
                            onClick={() => {
                              changeItemStatus(listing, "active");
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Col>

                      {/* Colored Button */}
                      {/* {listing.status === "active" ? (
                      <Col
                        className="activeButton"
                        style={{ padding: "0em 1em" }}
                      >
                        <Button
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      </Col>
                    ) : listing.status === "inactive" ? (
                      <Col
                        className="inactiveButton"
                        style={{ padding: "0em 1em" }}
                      >
                        <Button
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      </Col>
                    ) : (
                      ""
                    )} */}
                    </Col>
                  </Row>
                );
              })
            ) : showEmpty ? (
              <span className="empty-page">No Data Available</span>
            ) : (
              <React.Fragment>
                <img src={SvgGear} className="loading-gear" />
              </React.Fragment>
            )
            // <React.Fragment>
            //   <img src={SvgGear} className="loading-gear" />
            // </React.Fragment>
            // <span className="empty-page">No Data Available</span>
          }
        </Container>
      );
    }
    case "apparatus": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.item}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("apparatus");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Delete"
                        id={listing.id}
                        onClick={() => deleteData(listing.id)}
                      />
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "customer": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(moment(listing.created_date).format("yyyy-MM-DD")).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("customer");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "pic receive": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("pic receive");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "follow up by": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("follow up by");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "dismantle by-dismantle": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("dismantle by-dismantle");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "inspection by-dismantle": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("inspection by-dismantle");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "check by-dismantle": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("check by-dismantle");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "receive by-dismantle": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("receive by-dismantle");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "quote by-quotation": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("quote by-quotation");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "approve by-quotation": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("approve by-quotation");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "feedback -quotation": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("feedback -quotation");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "prepare by-pretest": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("prepare by-pretest");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "approve by-pretest": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("approve by-pretest");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "receive by-pretest": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("receive by-pretest");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "work confirm by-customer confirmation": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("work confirm by-customer confirmation");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "receive by-customer confirmation": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("receive by-customer confirmation");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "assembly by-job in progress": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("assembly by-job in progress");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "check by-job in progress": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("check by-job in progress");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "receive by-job in progress": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("receive by-job in progress");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "testing by-qc inspection": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("testing by-qc inspection");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "complete by-qc inspection": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("complete by-qc inspection");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "receive by-qc inspection": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("receive by-qc inspection");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "prepare by-report": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("prepare by-report");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "approve by-report": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("approve by-report");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "issue by-billing": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col className="textAlign-center">Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("issue by-billing");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    case "delivery by-billing": {
      return (
        <Container>
          <EditModalBox
            innerShow={show}
            closeHandler={customClose}
            caseNaming={naming}
            viewListData={viewListData[editID]}
            modalHead={modalHead}
          />

          <Row className="modalbox-cebox-title">
            {viewListHeader.length > 0 &&
              viewListHeader.map((listing) => {
                return (
                  <React.Fragment>
                    <Col>{listing}</Col>
                  </React.Fragment>
                );
              })}
            <Col>Action</Col>
          </Row>
          {viewListData.length > 0 ? (
            viewListData.length > 0 &&
            viewListData.map((listing, index) => {
              return (
                <Row className="modalbox-cebox-content" key={listing.id}>
                  <Col>{moment(listing.created_date).format("yyyy-MM-DD")}</Col>
                  <Col>{listing.name}</Col>
                  <Col className="col-4 d-flex modalbox-cebox-button textAlign-center">
                    <Col style={{ padding: "0em 1em" }}>
                      <Button
                        label="Edit"
                        onClick={() => {
                          setShow(true);
                          setNaming("delivery by-billing");
                          setEditID(index);
                        }}
                      />
                    </Col>
                    <Col style={{ padding: "0em 1em" }}>
                      {listing.status === "active" ? (
                        <Button
                          className="activeButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "inactive");
                          }}
                        />
                      ) : listing.status === "inactive" ? (
                        <Button
                          className="inactiveButton"
                          label={listing.status}
                          onClick={() => {
                            changeItemStatus(listing, "active");
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>
              );
            })
          ) : showEmpty ? (
            <span className="empty-page">No Data Available</span>
          ) : (
            <React.Fragment>
              <img src={SvgGear} className="loading-gear" />
            </React.Fragment>
          )}
        </Container>
      );
    }
    default: {
      return <></>;
    }
  }
}

export default {
  AddModalBox,
  ViewModalBox,
};
