import React from "react";

const Button = ({ label, isSubmitting, isValid, ...rest }) => {
  return (
    <span className="custom-button">
      <button
        type="submit"
        className="btn"
        disabled={!isValid || isSubmitting}
        {...rest}
      >
        {isSubmitting ? "Wait" : label}
      </button>
    </span>
  );
};

export default Button;
