//React element
import React from "react";
import { Route, Navigate } from "react-router-dom";
import authService from "../services/auth-service";

const ProtectedRoute = ({ children }) => {
  const { token } = authService.getCurrentUser();
  return token ? (
    children ? (
      children
    ) : (
      ""
    )
  ) : (
    <Navigate to="/" replace></Navigate>
  );
  // return token ? "" : <Navigate to="/" replace></Navigate>;
};

export default ProtectedRoute;
