//React element
import React from "react";
import { Field, ErrorMessage } from "formik";

const Textarea = ({ name, errors, ...rest }) => {
  return (
    <React.Fragment>
      <Field as="textarea" name={name} {...rest} />
      {/* <ErrorMessage className="form-error" name={name} component="div" /> */}
      {errors[name] ? <div className="form-error">{errors[name]}</div> : ""}
    </React.Fragment>
  );
};

export default Textarea;
