import http from "./http-service";
import config from "./../../config.json";

// const apiEndPoint = config.apiPoint + "/login";
const tokenKey = "token";
const userName = "name";
const userPosition = "position";

http.setToken(getToken());

function getToken() {
  return localStorage.getItem(tokenKey);
}

async function login(username, password) {
  const { data } = await http.post(config.apiPoint + "/login", {
    username: username,
    password: password,
  });
  // console.log("user local", username);
  // console.log("user data", data);
  const token = data.data.token || "";
  localStorage.setItem(tokenKey, token);
  // const user = data.username || "";
  // localStorage.setItem("user", username);
  const name = data.data.name || "";
  localStorage.setItem(userName, name);
  const position = data.data.position || "";
  localStorage.setItem(userPosition, position);

  if (token) return true;
  else return false;
}

function loginWithToken(token) {
  localStorage.setItem(tokenKey, token);
}

function logout() {
  localStorage.clear();
}

function getCurrentUser() {
  const token = localStorage.getItem(tokenKey);
  if (token) return { token };
  else return "";
}

async function createSetting(caseName, username, password, position) {
  switch (caseName) {
    case "position id": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        position_id: position,
        username: username,
        password: password,
        type: caseName,
      });
      return data;
    }
    case "apparatus": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        type: caseName,
        name: username,
        "power unit": password,
      });
      return data;
    }

    case "customer": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      // console.log("customer data");
      return data;
    }

    case "pic receive": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "follow up by": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "dismantle by-dismantle": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "inspection by-dismantle": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "check by-dismantle": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "receive by-dismantle": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "quote by-quotation": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "approve by-quotation": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "feedback -quotation": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "prepare by-pretest": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "approve by-pretest": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "receive by-pretest": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "work confirm by-customer confirmation": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "receive by-customer confirmation": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "assembly by-job in progress": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "check by-job in progress": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "receive by-job in progress": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "testing by-qc inspection": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "complete by-qc inspection": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "receive by-qc inspection": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "prepare by-report": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "approve by-report": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "issue by-billing": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }
    case "delivery by-billing": {
      const { data } = await http.post(config.apiPoint + "/setting", {
        name: username,
        type: caseName,
      });
      return data;
    }

    default: {
      break;
    }
  }
}

async function ChangePassword(password, new_password, confirm_new_password) {
  const response = await http.post(config.apiPoint + "/change_password", {
    password: password,
    new_password: new_password,
    confirm_new_password: confirm_new_password,
  });
  return response;
}

async function updateSetting(caseNaming, username, password, position, id) {
  switch (caseNaming) {
    case "position id": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        position_id: position,
        username: username,
        password: password,
        id: id,
        type: caseNaming,
      });
      return data;
    }
    case "apparatus": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        "power unit": position,
        id: id,
        type: caseNaming,
      });
      return data;
    }
    case "customer": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "pic receive": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "follow up by": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "dismantle by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "inspection by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "check by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "receive by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "quote by-quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "approve by-quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "feedback -quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "prepare by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "approve by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "receive by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "work confirm by-customer confirmation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "receive by-customer confirmation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "assembly by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "check by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "receive by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "testing by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "complete by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "receive by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "prepare by-report": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "approve by-report": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "issue by-billing": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }
    case "delivery by-billing": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
      });
      return data;
    }

    default: {
      break;
    }
  }
}

async function createJob(
  customer,
  pickDate,
  status,
  aparatus,
  rate,
  power,
  pic,
  gate,
  remark
) {
  const { data } = await http.post(config.apiPoint + "/job", {
    customer_id: customer,
    date: pickDate,
    job_status: status,
    apparatus_type_id: aparatus,
    rated_unit: rate,
    power_unit_id: power,
    pic_receive_id: pic,
    gate_pass_no: gate,
    remark: remark,
  });

  return data;
}

async function updateJob(
  job_no,
  customerID,
  pickDate,
  company,
  status,
  apparatus,
  rate,
  power,
  pic,
  gate,
  remark,
  follow
) {
  const { data } = await http.put(config.apiPoint + "/job/" + customerID, {
    job_no: job_no,
    customer_id: company,
    date: pickDate,
    job_status: status,
    apparatus_type_id: apparatus,
    rated_unit: rate,
    power_unit_id: power,
    pic_receive_id: pic,
    gate_pass_no: gate,
    remark: remark,
    follow_up_by_id: follow,
  });
  return data;
}

async function updateJobProcess(
  editName,
  customerID,
  state,
  pickDate,
  dismantleBy,
  inspectionBy,
  checkBy,
  receiveBy,
  quoteNo,
  quoteBy,
  approveBy,
  receiveByCust,
  remark,
  feedback,
  prepareBy,
  files,
  workConfirm,
  assemblyBy,
  testBy,
  completeBy,
  files1,
  files2,
  files3,
  deliveryState,
  issueBy,
  invoiceNo,
  deliveryBy,
  formData
) {
  switch (editName) {
    case "dismantle": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
          dismantle_by_id: dismantleBy,
          inspection_by_id: inspectionBy,
          check_by_id: checkBy,
          receive_by_id: receiveBy,
        }
      );
      return data;
    }
    case "quotation": {
      if (formData) {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          formData
        );
        return data;
      } else {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          {
            job_id: customerID,
            type: editName,
            date: pickDate,
            status: state,
            quotation_no: quoteNo,
            quote_by_id: quoteBy,
            approve_by_id: approveBy,
            receive_by_customer: receiveByCust,
            remark: remark,
            feedback: feedback,
            attachment: files,
          }
        );
        return data;
      }
    }
    case "pretest": {
      if (formData) {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          formData
        );
        return data;
      } else {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          {
            job_id: customerID,
            type: editName,
            date: pickDate,
            status: state,
            prepare_by_id: prepareBy,
            approve_by_id: approveBy,
            receive_by_id: receiveBy,
            remark: remark,
            attachment: files,
          }
        );
        return data;
      }
    }
    case "customer confirmation": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
          work_confirm_by_id: workConfirm,
          receive_by_id: receiveBy,
          remark: remark,
        }
      );
      return data;
    }
    case "purchasing": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
        }
      );
      return data;
    }
    case "job in progress": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
          assembly_by_id: assemblyBy,
          check_by_id: checkBy,
          receive_by_id: receiveBy,
          remark: remark,
        }
      );
      return data;
    }
    case "qc inspection": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
          testing_by_id: testBy,
          complete_by_id: completeBy,
          receive_by_id: receiveBy,
          remark: remark,
        }
      );
      return data;
    }
    case "report": {
      if (formData) {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          formData
        );
        return data;
      } else {
        const { data } = await http.post(
          config.apiPoint + "/update_job_process",
          {
            job_id: customerID,
            type: editName,
            date: pickDate,
            status: state,
            prepare_by_id: prepareBy,
            approve_by_id: approveBy,
            remark: remark,
            white_card: files,
            attachment_1: files1,
            attachment_2: files2,
            attachment_3: files3,
          }
        );
        return data;
      }
    }
    case "billing": {
      const { data } = await http.post(
        config.apiPoint + "/update_job_process",
        {
          job_id: customerID,
          type: editName,
          date: pickDate,
          status: state,
          delivery_status: deliveryState,
          invoice: invoiceNo,
          issue_by_id: issueBy,
          delivery_by_id: deliveryBy,
          remark: remark,
        }
      );
      return data;
    }

    default: {
      break;
    }
  }
}

async function updatePurchaseProcess(formData) {
  const { data } = await http.post(
    config.apiPoint + "/update_job_process",
    formData
  );

  return data;
}

async function addPurchaseProcess(formData) {
  // console.log("addPurchase api calling", method);

  const { data } = await http.post(
    config.apiPoint + "/update_job_process",
    formData
  );

  return data;
}

async function deletePurchaseProcess(formData) {
  const { data } = await http.post(
    config.apiPoint + "/update_job_process",
    formData
  );

  return data;
}

async function updateSettingViewStatus(
  caseNaming,
  username,
  password,
  position,
  id,
  status
) {
  switch (caseNaming) {
    case "position id": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        position_id: position,
        username: username,
        password: password,
        id: id,
        type: caseNaming,
        status: status,
      });
      return data;
    }
    case "apparatus": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        "power unit": position,
        id: id,
        type: caseNaming,
      });
      return data;
    }
    case "customer": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });

      return data;
    }
    case "pic receive": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "follow up by": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "dismantle by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "inspection by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "check by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "receive by-dismantle": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "quote by-quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "approve by-quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "feedback -quotation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "prepare by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "approve by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "receive by-pretest": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "work confirm by-customer confirmation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "receive by-customer confirmation": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "assembly by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "check by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "receive by-job in progress": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "testing by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "complete by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "receive by-qc inspection": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "prepare by-report": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "approve by-report": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "issue by-billing": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }
    case "delivery by-billing": {
      const { data } = await http.put(config.apiPoint + "/setting/1", {
        name: username,
        id: position,
        type: caseNaming,
        status: id,
      });
      return data;
    }

    default: {
      break;
    }
  }
}

async function deleteJob(id) {
  const response = await http.delete(config.apiPoint + "/job/" + id);

  return response;
}

async function deleteApparatus(id) {
  const response = await http.delete(config.apiPoint + "/apparatus_type/" + id);

  return response;
}

async function deleteMedia(id) {
  const { data } = await http.post(config.apiPoint + "/media?id=" + id);

  return data;
}

async function exportCSV(id) {
  const response = await http.post(config.apiPoint + "/export_csv", {
    id: id,
  });

  return response;
}

export default {
  getToken,
  login,
  loginWithToken,
  logout,
  getCurrentUser,
  createSetting,
  updateSetting,
  createJob,
  updateJob,
  updateJobProcess,
  updatePurchaseProcess,
  ChangePassword,
  addPurchaseProcess,
  deletePurchaseProcess,
  updateSettingViewStatus,
  deleteJob,
  deleteApparatus,
  deleteMedia,
  exportCSV,
};
