//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import _, { forEach, isEmpty, isNull } from "lodash";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import moment from "moment";

//Form element
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikForm from "./formikForm";

//Elements
import Button from "./../common/elements/plainButton";
import ShowFileImg from "./../common/elements/showFileImg";

//Common
import AddForm from "./editProcessTabEditNew";

//Config
import { ImageConfig } from "./../../config/ImageConfig";

//API integrate
import authService from "../services/auth-service";
import renderService from "../services/render-service";

//Pop up
import RUsure from "./popup/ruSureBox";

//Editable icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { isString } from "@sentry/utils";

const EditTabForm = ({
  value,
  name,
  editName,
  customerID,
  selectValue,
  setPurchaseList,
  refresh,
  setRefresh,
  editButton,
  setEditButton,
  refreshPurchaseSave,
  setRefreshPurchaseSave,
  refreshMedia,
  setRefreshMedia,
  refreshPage,
  setRefreshPage,
}) => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState([]);
  const [selectedImage3, setSelectedImage3] = useState([]);
  const [selectedImage4, setSelectedImage4] = useState([]);
  const [info, setInfo] = useState({});
  const [dateTime, setDateTime] = useState(new Date());
  const [editing, setEditing] = useState(false);
  const [showBox, setShowBox] = useState(false);
  // const [rUsure, setRUsure] = useState({});
  // const [confirmState, setConfirmState] = useState(false);
  //Set Options
  const [dismantle1, setDismantle1] = useState({});
  const [dismantle2, setDismantle2] = useState({});
  const [dismantle3, setDismantle3] = useState({});
  const [dismantle4, setDismantle4] = useState({});
  const [quotation1, setQuotation1] = useState({});
  const [quotation2, setQuotation2] = useState({});
  const [quotation3, setQuotation3] = useState({});
  const [pretest1, setPretest1] = useState({});
  const [pretest2, setPretest2] = useState({});
  const [pretest3, setPretest3] = useState({});
  const [customer1, setCustomer1] = useState({});
  const [customer2, setCustomer2] = useState({});
  const [job1, setJob1] = useState({});
  const [job2, setJob2] = useState({});
  const [job3, setJob3] = useState({});
  const [qc1, setQc1] = useState({});
  const [qc2, setQc2] = useState({});
  const [qc3, setQc3] = useState({});
  const [report1, setReport1] = useState({});
  const [report2, setReport2] = useState({});
  const [billing1, setBilling1] = useState({});
  const [billing2, setBilling2] = useState({});
  const [uploadFile1, setUploadFile1] = useState("Occupied");
  const [uploadFile2, setUploadFile2] = useState("Occupied");
  const [uploadFile3, setUploadFile3] = useState("Occupied");
  const [uploadFile4, setUploadFile4] = useState("Occupied");
  // const [uploadFile, setUploadFile] = useState({});

  // console.log("selectedImage", selectedImage);
  // console.log("edit form setPurchaseList", setPurchaseList);
  // console.log("Edit form value", value);

  const getEditForm = async () => {
    // const response = await renderService.editJobInfo("");
    // console.log("getEditForm test", response.data.data);
    switch (name) {
      case "dismantle": {
        const response1 = await renderService.editJobInfo(
          "dismantle by-dismantle"
        );
        const response2 = await renderService.editJobInfo(
          "inspection by-dismantle"
        );
        const response3 = await renderService.editJobInfo("check by-dismantle");
        const response4 = await renderService.editJobInfo(
          "receive by-dismantle"
        );
        setDismantle1(response1.data.data);
        setDismantle2(response2.data.data);
        setDismantle3(response3.data.data);
        setDismantle4(response4.data.data);

        break;
      }
      case "quotation": {
        const response1 = await renderService.editJobInfo("quote by-quotation");
        const response2 = await renderService.editJobInfo(
          "approve by-quotation"
        );
        const response3 = await renderService.editJobInfo(
          "feedback -quotation"
        );
        setQuotation1(response1.data.data);
        setQuotation2(response2.data.data);
        setQuotation3(response3.data.data);

        break;
      }
      case "pretest": {
        const response1 = await renderService.editJobInfo("prepare by-pretest");
        const response2 = await renderService.editJobInfo("approve by-pretest");
        const response3 = await renderService.editJobInfo("receive by-pretest");
        setPretest1(response1.data.data);
        setPretest2(response2.data.data);
        setPretest3(response3.data.data);

        break;
      }
      case "customer": {
        const response1 = await renderService.editJobInfo(
          "work confirm by-customer confirmation"
        );
        const response2 = await renderService.editJobInfo(
          "receive by-customer confirmation"
        );
        setCustomer1(response1.data.data);
        setCustomer2(response2.data.data);

        break;
      }
      // case "purchasing": {
      //   break;
      // }
      case "job": {
        const response1 = await renderService.editJobInfo(
          "assembly by-job in progress"
        );
        const response2 = await renderService.editJobInfo(
          "check by-job in progress"
        );
        const response3 = await renderService.editJobInfo(
          "receive by-job in progress"
        );
        setJob1(response1.data.data);
        setJob2(response2.data.data);
        setJob3(response3.data.data);

        break;
      }
      case "qc": {
        const response1 = await renderService.editJobInfo(
          "testing by-qc inspection"
        );
        const response2 = await renderService.editJobInfo(
          "complete by-qc inspection"
        );
        const response3 = await renderService.editJobInfo(
          "receive by-qc inspection"
        );
        setQc1(response1.data.data);
        setQc2(response2.data.data);
        setQc3(response3.data.data);

        break;
      }
      case "report": {
        const response1 = await renderService.editJobInfo("prepare by-report");
        const response2 = await renderService.editJobInfo("approve by-report");
        setReport1(response1.data.data);
        setReport2(response2.data.data);

        break;
      }
      case "billing": {
        const response1 = await renderService.editJobInfo("issue by-billing");
        const response2 = await renderService.editJobInfo(
          "delivery by-billing"
        );
        setBilling1(response1.data.data);
        setBilling2(response2.data.data);

        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    getEditForm().catch((e) => {
      // console.log(e);
    });
    if (value) {
      setInfo(value.data);
    }
    // if (showBox) {
    //   setShowBox(true);
    // }
    // if (confirmState === true) {
    //   onSubmit(editName, customerID, rUsure);
    // }
  }, [showBox]);

  async function onSubmit(
    editName,
    customerID,
    values,
    setRefresh,
    setSubmitting
  ) {
    const formData = new FormData();
    let count;
    // console.log("values",values)
    if (editName === "quotation") {
      formData.append("job_id", customerID);
      formData.append("type", editName);
      formData.append(
        "date",
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss")
      );
      formData.append("status", values.state);
      formData.append("quotation_no", values.quoteNo);
      formData.append("quote_by_id", values.quoteBy);
      formData.append("approve_by_id", values.approveBy);
      formData.append("receive_by_customer", values.receiveByCust);
      formData.append("remark", values.remark);
      formData.append("feedback", values.feedback);

      var attachmentArr = [];
      if (values.files1) {
        attachmentArr.push(values.files1);
      }
      if (values.files2) {
        attachmentArr.push(values.files2);
      }
      if (values.files3) {
        attachmentArr.push(values.files3);
      }
      for (count = 0; count < attachmentArr.length; count++) {
        formData.append(`attachment[${count}]`, attachmentArr[count]);
      }
    } else if (editName === "pretest") {
      formData.append("job_id", customerID);
      formData.append("type", editName);
      formData.append(
        "date",
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss")
      );
      formData.append("status", values.state);
      formData.append("prepare_by_id", values.prepareBy);
      formData.append("approve_by_id", values.approveBy);
      formData.append("receive_by_id", values.receiveBy);
      formData.append("remark", values.remark);
      if (values.files) {
        formData.append("attachment[0]", values.files);
      }
    } else if (editName === "report") {
      formData.append("job_id", customerID);
      formData.append("type", editName);
      formData.append(
        "date",
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss")
      );
      formData.append("status", values.state);
      formData.append("prepare_by_id", values.prepareBy);
      formData.append("approve_by_id", values.approveBy);
      formData.append("remark", values.remark);
      if (values.files) {
        formData.append("white_card[0]", values.files);
      }
      if (values.files1) {
        formData.append("attachment_1[0]", values.files1);
      }
      if (values.files2) {
        formData.append("attachment_2[0]", values.files2);
      }
      if (values.files3) {
        formData.append("attachment_3[0]", values.files3);
      }
    }

    try {
      const response = await authService.updateJobProcess(
        editName,
        customerID,
        values.state,
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss"),
        values.dismantleBy,
        values.inspectionBy,
        values.checkBy,
        values.receiveBy,
        values.quoteNo,
        values.quoteBy,
        values.approveBy,
        values.receiveByCust,
        values.remark,
        values.feedback,
        values.prepareBy,
        values.files,
        values.workConfirm,
        values.assemblyBy,
        values.testBy,
        values.completeBy,
        values.files1,
        values.files2,
        values.files3,
        values.deliveryState,
        values.issueBy,
        values.invoiceNo,
        values.deliveryBy,
        formData
      );
      if (response) {
        toast.configure();
        toast("Success", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
        // window.location.reload();
        setEditButton(!editButton);
        setRefresh(!refresh);
        setRefreshPage(!refreshPage);
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const error = ex.response.values;
        toast.configure();
        toast("Wrong Input", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
      setSubmitting(false);
    }
  }

  async function deleteMedia(id) {
    try {
      const response = await authService.deleteMedia(id);

      if (response) {
        toast.configure();
        toast("Success delete attachment", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
        setRefreshMedia(!refreshMedia);
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const error = ex.response.values;
        toast.configure();
        toast("Fail delete attachment", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
    }
  }

  const statusOptions = [
    { label: "Please Select -", id: "" },
    { label: "Checked", id: "bypass" },
    { label: "Complete", id: "complete" },
  ];

  const imageChange = (e) => {
    // if (e.target.files && e.target.files.length > 0) {
    //   Array.from(e.target.files).forEach((file) => {
    //     setSelectedImage((selectedImage) => [...selectedImage, file]);
    //   });
    // }

    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files);
    }
  };

  const imageChange2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage2(e.target.files);
    }
  };

  const imageChange3 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage3(e.target.files);
    }
  };

  const imageChange4 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage4(e.target.files);
    }
  };

  const customClose = () => {
    setEditing(false);
    // setShowBox(false);
    getEditForm();
  };

  // const customClose2 = () => {
  //   setShowBox(false);
  // };

  const getData = (name) => {
    switch (name) {
      case "dismantle": {
        let data = {
          state: selectValue.dismantle_status ? selectValue.dismantle_status.toLowerCase():"",
          pickDate: dateTime,
          // pickDate: new Date(),
          // pickDate: "2022-03-31 14:22:20",
          dismantleBy: selectValue.dismantle_by,
          inspectionBy: selectValue.inspection_by,
          checkBy: selectValue.check_by,
          receiveBy: selectValue.receive_by,
        };

        return data;
      }
      case "quotation": {
        let data = {
          state: selectValue.quotation_status ? selectValue.quotation_status.toLowerCase() : "",
          pickDate: dateTime,
          quoteNo: selectValue.quotation_no ? selectValue.quotation_no : "",
          quoteBy: selectValue.quote_by,
          approveBy: selectValue.approve_by,
          receiveByCust: selectValue.receive_by_customer
            ? selectValue.receive_by_customer
            : "",
          remark: selectValue.remark ? selectValue.remark : "",
          feedback: selectValue.feedback,
          // files1: selectValue.attachment[0],
          // files2: selectValue.attachment[1],
          // files3: selectValue.attachment[2],
          // files1: selectValue.attachment[0] ? selectValue.attachment[0] : "",
          // files2: selectValue.attachment[1] ? selectValue.attachment[1] : "",
          // files3: selectValue.attachment[2] ? selectValue.attachment[2] : "",
          // files: selectValue.attachment,
        };
        return data;
      }
      case "pretest": {
        let data = {
          state: selectValue.pretest_status ? selectValue.pretest_status.toLowerCase() : "",
          pickDate: dateTime,
          prepareBy: selectValue.prepare_by,
          approveBy: selectValue.approve_by,
          receiveBy: selectValue.receive_by,
          remark: selectValue.remark ? selectValue.remark : "",
          // files: selectValue.attachment[0],
          // files: "",
        };
        return data;
      }
      case "customer": {
        let data = {
          state: selectValue.customer_confirmation_status ? selectValue.customer_confirmation_status.toLowerCase():"",
          pickDate: dateTime,
          workConfirm: selectValue.work_confirm_by,
          receiveBy: selectValue.receive_by,
          remark: selectValue.remark ? selectValue.remark : "",
        };
        return data;
      }
      case "purchasing": {
        let data = {
          state: selectValue.purchasing_status ? selectValue.purchasing_status.toLowerCase():"",
          pickDate: dateTime,
        };
        return data;
      }
      case "job": {
        let data = {
          state: selectValue.job_in_progress_status ? selectValue.job_in_progress_status.toLowerCase():"",
          pickDate: dateTime,
          assemblyBy: selectValue.assembly_by,
          checkBy: selectValue.check_by,
          receiveBy: selectValue.receive_by,
          remark: selectValue.remark ? selectValue.remark : "",
        };
        return data;
      }
      case "qc": {
        let data = {
          state: selectValue.qc_inspection_status ? selectValue.qc_inspection_status.toLowerCase():"",
          pickDate: dateTime,
          testBy: selectValue.testing_by,
          completeBy: selectValue.complete_by,
          receiveBy: selectValue.receive_by,
          remark: selectValue.remark ? selectValue.remark : "",
        };
        return data;
      }
      case "report": {
        let data = {
          state: selectValue.report_status ? selectValue.report_status.toLowerCase():"",
          pickDate: dateTime,
          prepareBy: selectValue.prepare_by,
          approveBy: selectValue.approve_by,
          remark: selectValue.remark ? selectValue.remark : "",
          // files: selectValue.white_card[0] ? selectValue.white_card[0] : "",
          // files1: selectValue.attachment_1[0] ? selectValue.attachment_1 : "",
          // files2: selectValue.attachment_2[0] ? selectValue.attachment_2 : "",
          // files3: selectValue.attachment_3[0] ? selectValue.attachment_3 : "",
          // files: "",
          // files1: "",
          // files2: "",
          // files3: "",
        };

        return data;
      }
      case "billing": {
        let data = {
          state: selectValue.billing_status
            ? selectValue.billing_status.toLowerCase()
            : "",
          deliveryState: selectValue.delivery_status
            ? selectValue.delivery_status.toLowerCase()
            : "",
          pickDate: dateTime,
          issueBy: selectValue.issue_by,
          invoiceNo: selectValue.invoice_no ? selectValue.invoice_no : "",
          deliveryBy: selectValue.delivery_by,
          remark: selectValue.remark ? selectValue.remark : "",
        };

        return data;
      }

      default: {
        return <></>;
      }
    }
  };

  const getSchema = (name) => {
    switch (name) {
      case "dismantle": {
        const schema = Yup.object({
          state: Yup.string(),
          dismantleBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          inspectionBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          checkBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          receiveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "quotation": {
        const schema = Yup.object({
          state: Yup.string(),
          quoteBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          approveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "pretest": {
        const schema = Yup.object({
          state: Yup.string(),
          //// pickDate: Yup.string().required("Required!"),
          // prepareBy: Yup.string().required("Required!"),
          // approveBy: Yup.string().required("Required!"),
          // receiveBy: Yup.string().required("Required!"),
          ////remark: Yup.string().required("Required!"),
          //// files: Yup.string().required("Required!"),
          prepareBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          approveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          receiveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "customer": {
        const schema = Yup.object({
          state: Yup.string(),
          //// pickDate: Yup.string().required("Required!"),
          // workConfirm: Yup.string().required("Required!"),
          // receiveBy: Yup.string().required("Required!"),
          ////remark: Yup.string().required("Required!"),
          workConfirm: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          receiveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "purchasing": {
        const schema = Yup.object({
          state: Yup.string(),
          // pickDate: Yup.string().required("Required!"),
        });

        return schema;
      }
      case "job": {
        const schema = Yup.object({
          state: Yup.string(),
          //// pickDate: Yup.string().required("Required!"),
          // assemblyBy: Yup.string().required("Required!"),
          // checkBy: Yup.string().required("Required!"),
          // receiveBy: Yup.string().required("Required!"),
          ////remark: Yup.string().required("Required!"),
          assemblyBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          checkBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          receiveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "qc": {
        const schema = Yup.object({
          state: Yup.string(),
          //// pickDate: Yup.string().required("Required!"),
          // testBy: Yup.string().required("Required!"),
          // completeBy: Yup.string().required("Required!"),
          // receiveBy: Yup.string().required("Required!"),
          ////remark: Yup.string().required("Required!"),
          testBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          completeBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          receiveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "report": {
        const schema = Yup.object({
          state: Yup.string(),
          //// pickDate: Yup.string().required("Required!"),
          // prepareBy: Yup.string().required("Required!"),
          // approveBy: Yup.string().required("Required!"),
          ////remark: Yup.string().required("Required!"),
          //// files: Yup.string().required("Required!"),
          //// files1: Yup.string().required("Required!"),
          //// files2: Yup.string().required("Required!"),
          //// files3: Yup.string().required("Required!"),
          prepareBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
          approveBy: Yup.string().when("state", {
            is: "complete",
            then: Yup.string().required("Required!"),
            otherwise: Yup.string(),
          }),
        });

        return schema;
      }
      case "billing": {
        const schema = Yup.object({
          //state: Yup.string().required("Required!"),
          //deliveryState: Yup.string().required("Required!"),
          // pickDate: Yup.string().required("Required!"),
          //issueBy: Yup.string().required("Required!"),
          //invoiceNo: Yup.string().required("Required!"),
          //deliveryBy: Yup.string().required("Required!"),
          //remark: Yup.string().required("Required!"),
        });

        return schema;
      }

      default: {
        return <></>;
      }
    }
  };

  const showContent = (info, name, setFieldValue, errors) => {
    if (!_.isEmpty(info)) {
      switch (name) {
        case "dismantle": {
          return (
            <React.Fragment>
              <Row>Dismantle Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  // onChange={setDateTime}
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Dismantle By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("dismantleBy", dismantle1, errors)}
              </Row>
              <Row>Inspection By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("inspectionBy", dismantle2, errors)}
              </Row>
              <Row>Checked By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("checkBy", dismantle3, errors)}
              </Row>
              <Row>Received By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("receiveBy", dismantle4, errors)}
              </Row>
            </React.Fragment>
          );
        }
        case "quotation": {
          return (
            <React.Fragment>
              <Row>Quotation Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Quotation No</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderPlainText("quoteNo", "", errors)}
              </Row>
              <Row>Quoted By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("quoteBy", quotation1, errors)}
              </Row>
              <Row>Approved By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("approveBy", quotation2, errors)}
              </Row>
              <Row>Received By Customer</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderPlainText("receiveByCust", "", errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
              <Row>Feedback</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("feedback", quotation3, errors)}
              </Row>
              <Row>Attached PDF File 1</Row>
              <Row>
                {info.attachment[0] ? (
                  uploadFile1 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment[0].id);
                            // setUploadFile((uploadFile.upFile1 = ""));
                            setUploadFile1("");
                          // console.log("2")

                          }}
                        />
                      </div>
                      <div className="editprocessTabEditForm-link">
                        {info.attachment[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files1"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange(e);
                          // console.log("1")
                          setFieldValue("files1", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files1"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange(e);
                        setFieldValue("files1", e.target.files[0]);
                        // console.log("q",e.target.files[0])
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                  </React.Fragment>
                )}
              </Row>
              <Row>Attached PDF File 2</Row>
              <Row>
                {info.attachment[1] ? (
                  uploadFile2 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment[1].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment[1].id);
                            setUploadFile2("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment[1].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[1].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment[1].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files2"
                        type="file"
                        onChange={(e) => {
                          imageChange2(e);
                          setFieldValue("files2", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage2[0] ? (
                        selectedImage2[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage2[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage2[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage2[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage2[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files2"
                      type="file"
                      onChange={(e) => {
                        imageChange2(e);
                        setFieldValue("files2", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage2[0] ? (
                      selectedImage2[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage2[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage2[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage2[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage2[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
              <Row>Attached PDF File 3</Row>
              <Row>
                {info.attachment[2] ? (
                  uploadFile3 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment[2].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment[2].id);
                            setUploadFile3("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment[2].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[2].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment[2].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files3"
                        type="file"
                        onChange={(e) => {
                          imageChange3(e);
                          setFieldValue("files3", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage3[0] ? (
                        selectedImage3[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage3[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage3[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage3[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage3[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files3"
                      type="file"
                      onChange={(e) => {
                        imageChange3(e);
                        setFieldValue("files3", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage3[0] ? (
                      selectedImage3[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage3[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage3[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage3[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage3[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
            </React.Fragment>
          );
        }
        case "pretest": {
          return (
            <React.Fragment>
              <Row>Pretest Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Prepare By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("prepareBy", pretest1, errors)}
              </Row>
              <Row>Approved By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("approveBy", pretest2, errors)}
              </Row>
              <Row>Received By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("receiveBy", pretest3, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
              <Row>Attached PDF File</Row>
              <Row>
                {info.attachment[0] ? (
                  uploadFile1 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment[0].id);
                            setUploadFile1("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment[0].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange(e);
                          // setUploadFile(e.target.files[0]);
                          // setFieldValue(handleFile);
                          setFieldValue("files", e.target.files[0]);
                          // console.log("onChange event", e.target.files);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage[0] ? (
                        selectedImage[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange(e);
                        // setUploadFile(e.target.files[0]);
                        // setFieldValue(handleFile);
                        setFieldValue("files", e.target.files[0]);
                        // console.log("onChange event", e.target.files);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*"
                    />
                    {/* {selectedImage[0] ? (
                      selectedImage[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
                {/* {selectedImage &&
                  selectedImage.map((url) => {
                    if (url.type === "image/png") {
                      return (
                        <img
                          src={URL.createObjectURL(url)}
                          alt=""
                          className="uploadfile-preview"
                        />
                      );
                    } else if (url.type === "image/jpeg") {
                      return (
                        <img
                          src={URL.createObjectURL(url)}
                          alt=""
                          className="uploadfile-preview"
                        />
                      );
                    } else {
                      return (
                        // <div className="uploadfile-preview">
                        <img
                          // src={URL.createObjectURL(url)}
                          src={
                            ImageConfig[url.type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                        // </div>
                      );
                    }
                  })} */}
              </Row>
            </React.Fragment>
          );
        }
        case "customer": {
          return (
            <React.Fragment>
              <Row>Customer Confirm Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Work Confirm By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("workConfirm", customer1, errors)}
              </Row>
              <Row>Received By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("receiveBy", customer2, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
            </React.Fragment>
          );
        }
        case "purchasing": {
          return (
            <React.Fragment>
              <AddForm
                show={editing}
                closeHandler={customClose}
                editName="purchasing list"
                customerID={customerID}
                setPurchaseList={setPurchaseList}
                refresh={refresh}
                setRefresh={setRefresh}
                refreshPurchaseSave={refreshPurchaseSave}
                setRefreshPurchaseSave={setRefreshPurchaseSave}
              />

              <Row className="editForm-cebox-button-submit">
                <Button
                  label="+ Add Item List"
                  onClick={() => {
                    {
                      setEditing(true);
                    }
                  }}
                />
              </Row>
              <Row>Purchasing Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
            </React.Fragment>
          );
        }
        case "job": {
          return (
            <React.Fragment>
              <Row>Job in Progress Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Assembly By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("assemblyBy", job1, errors)}
              </Row>
              <Row>Checked By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("checkBy", job2, errors)}
              </Row>
              <Row>Received By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("receiveBy", job3, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
            </React.Fragment>
          );
        }
        case "qc": {
          return (
            <React.Fragment>
              <Row>QC Inspection Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Testing By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("testBy", qc1, errors)}
              </Row>
              <Row>Completed By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("completeBy", qc2, errors)}
              </Row>
              <Row>Received By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("receiveBy", qc3, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
            </React.Fragment>
          );
        }
        case "report": {
          return (
            <React.Fragment>
              <Row> Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Prepare By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("prepareBy", report1, errors)}
              </Row>
              <Row>Approved By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("approveBy", report2, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
              <Row>Attached PDF File White Card</Row>
              <Row>
                {info.white_card[0] ? (
                  uploadFile1 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.white_card[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.white_card[0].id);
                            setUploadFile1("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.white_card[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.white_card[0].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.white_card[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange4(e);
                          setFieldValue("files", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage4[0] ? (
                        selectedImage4[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage4[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage4[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage4[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage4[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange4(e);
                        setFieldValue("files", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage4[0] ? (
                      selectedImage4[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage4[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage4[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage4[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage4[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
              <Row>Attached PDF File Report 1</Row>
              <Row>
                {info.attachment_1[0] ? (
                  uploadFile2 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment_1[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment_1[0].id);
                            setUploadFile2("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment_1[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_1[0].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment_1[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files1"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange(e);
                          setFieldValue("files1", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage[0] ? (
                        selectedImage[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files1"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange(e);
                        setFieldValue("files1", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage[0] ? (
                      selectedImage[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
              <Row>Attached PDF File Report 2</Row>
              <Row>
                {info.attachment_2[0] ? (
                  uploadFile3 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment_2[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment_2[0].id);
                            setUploadFile3("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment_2[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_2[0].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment_2[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files2"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange2(e);
                          setFieldValue("files2", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage2[0] ? (
                        selectedImage2[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage2[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage2[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage2[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage2[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files2"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange2(e);
                        setFieldValue("files2", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage2[0] ? (
                      selectedImage2[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage2[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage2[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage2[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage2[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
              <Row>Attached PDF File Report 3</Row>
              <Row>
                {info.attachment_3[0] ? (
                  uploadFile4 === "Occupied" ? (
                    <Row>
                      <div className="icon-circleX">
                        <FontAwesomeIcon
                          id={info.attachment_3[0].id}
                          icon={faCircleXmark}
                          onClick={() => {
                            deleteMedia(info.attachment_3[0].id);
                            setUploadFile4("");
                          }}
                        />
                      </div>
                      {/* <img
                        src={info.attachment_3[0].url}
                        alt=""
                        className="uploadfile-preview"
                      /> */}
                      {/* <ShowFileImg data={info.attachment_3[0].url} /> */}
                      <div className="editprocessTabEditForm-link">
                        {info.attachment_3[0].name}
                      </div>
                    </Row>
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files3"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange3(e);
                          setFieldValue("files3", e.target.files[0]);
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage3[0] ? (
                        selectedImage3[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage3[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage3[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage3[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage3[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )
                ) : (
                  <React.Fragment>
                    <input
                      id="photo"
                      name="files3"
                      type="file"
                      // multiple
                      onChange={(e) => {
                        imageChange3(e);
                        setFieldValue("files3", e.target.files[0]);
                      }}
                      accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                    />
                    {/* {selectedImage3[0] ? (
                      selectedImage3[0].type === "image/png" ? (
                        <img
                          src={URL.createObjectURL(selectedImage3[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : selectedImage3[0].type === "image/jpeg" ? (
                        <img
                          src={URL.createObjectURL(selectedImage3[0])}
                          alt=""
                          className="uploadfile-preview"
                        />
                      ) : (
                        <img
                          src={
                            ImageConfig[selectedImage3[0].type.split("/")[1]] ||
                            ImageConfig["default"]
                          }
                          alt=""
                          className="uploadfile-preview"
                        />
                      )
                    ) : (
                      ""
                    )} */}
                  </React.Fragment>
                )}
              </Row>
            </React.Fragment>
          );
        }
        case "billing": {
          return (
            <React.Fragment>
              <Row>Billing Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect("state", statusOptions, errors)}
              </Row>
              <Row>Delivery Status</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelect(
                  "deliveryState",
                  statusOptions,
                  errors
                )}
              </Row>
              <Row>Date</Row>
              <Row>
                <DateTimePicker
                  onChange={(e) => {
                    {
                      setFieldValue("pickDate", e);
                      setDateTime(e);
                    }
                  }}
                  value={dateTime}
                  name="pickDate"
                  className="modalbox-jobox-datetime"
                  format="yyyy-MM-dd hh:mm:ss"
                  clearIcon={null}
                  disabled
                />
              </Row>
              <Row>Issue By</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("issueBy", billing1, errors)}
              </Row>
              <Row>Invoice / DN No</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderPlainText("invoiceNo", "", errors)}
              </Row>
              <Row>Delivery BY</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderSelectV2("deliveryBy", billing2, errors)}
              </Row>
              <Row>Remark</Row>
              <Row className="editprocessTabEditForm-title">
                {FormikForm.renderTextarea("remark", errors)}
              </Row>
            </React.Fragment>
          );
        }

        default: {
          return <></>;
        }
      }
    }
  };

  return (
    <Container className="editprocessTabEditForm-container">
      {/* <RUsure
        show={showBox}
        setConfirmState={setConfirmState}
        closeHandler={customClose2}
        value={rUsure}
      /> */}

      <Formik
        initialValues={getData(name)}
        validationSchema={getSchema(name)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(editName, customerID, values, setRefresh, setSubmitting);
          // console.log("submitted value", values);
          // setShowBox(true);
          // setRUsure(values);
        }}
      >
        {({ handleReset, setFieldValue, isSubmitting, isValid, errors }) => (
          <Form>
            {showContent(info, name, setFieldValue, errors)}
            <Row className="editmodalbox-cebox-buttonRow">
              <Col className="col-6">
                <Button
                  label={"Clear"}
                  onClick={() => {
                    handleReset();
                    setFieldValue("files1","")
                    setUploadFile1("")
                  }}
                />
              </Col>
              <Col className="editForm-cebox-button-submit col-6">
                {FormikForm.renderButton("Submit", isSubmitting, isValid)}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditTabForm;
