//React element
import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

//CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import "react-toastify/dist/ReactToastify.css"

//Case switch
import switching from "./components/common/switchCase"

//Pages
import Screen from "./components/screen/screen"
import Template from "./components/screen/template"
import Login from "./components/screen/login"
import Main from "./components/screen/main"
import Archives from "./components/screen/archives"
import Setting from "./components/screen/setting"
import Edit from "./components/screen/mainEdit"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route
          path="/temp"
          element={
            <Screen
              publicAuthorize={false}
              header={switching.getHeader(1)}
              component={Template}
            />
          }
        />
        <Route
          path="/main"
          element={
            <Screen
              publicAuthorize={false}
              header={switching.getHeader(1)}
              component={Main}
            />
          }
        />
        <Route
          path="/archives"
          element={
            <Screen
              publicAuthorize={false}
              header={switching.getHeader(2)}
              component={Archives}
            />
          }
        />
        <Route
          path="/setting"
          element={
            <Screen
              publicAuthorize={false}
              header={switching.getHeader(3)}
              component={Setting}
            />
          }
        />
        <Route
          path="/edit"
          element={
            <Screen
              publicAuthorize={false}
              header={switching.getHeader(4)}
              component={Edit}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
