import React from "react";
import { Field, ErrorMessage } from "formik";

const Input = ({ name, label, type, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <Field className="form-control" name={name} type={type} {...rest} />
      <ErrorMessage className="form-error" name={name} component="div" />
    </div>
  );
};

export default Input;
