//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import _ from "lodash";

//Common
import EditProcessTab from "../common/editProcessTab";

//Elements
import TextElement from "./../common/elements/text";
import SignElement from "./../common/elements/status";
import BodyProcess from "./../common/elements/titleText";
import BodyTitle from "./../common/elements/titleText";

//Api integration
import renderService from "./../services/render-service";

const MainEdit = ({ exportData, setExportData, setExportID }) => {
  const location = useLocation();
  const [jobList, setJobList] = useState({
    dismantle: {},
    pretest: {},
    quotation: {},
    customer_confirmation: {},
    purchasing: {},
    job_in_progress: {},
    qc_inspection: {},
    report: {},
    billing: {},
  });
  const custID = location.state.custData;
  const prevPath = location.pathname;
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const getJobList = async () => {
    const response = await renderService.jobInfo(custID);
    setJobList(response.data);
  };

  useEffect(() => {
    getJobList();
    localStorage.setItem("prevPath", prevPath);
  }, [refreshStatus, refreshPage]);

  useEffect(() => {
    setExportData([custID]);
  }, [exportData, custID]);

  return (
    <Container fluid className="body-editprocess">
      <Row className="editprocess-titleRow1">
        <Col className="col-1" />
        <Col>
          <Row>
            <Col className="title-padding">
              <BodyTitle title="Job No." />
            </Col>
            <Col className="title-padding">
              <BodyTitle title="Start Date" />
            </Col>
            <Col className="title-padding">
              <BodyTitle title="Company Name" />
            </Col>
            <Col className="title-padding">
              <BodyTitle title="Status" />
            </Col>
          </Row>
        </Col>
        <Col className="col-3" />
      </Row>
      <Row className="editprocess-titleRow2">
        <Col className="col-1 edit-title-padding" />
        {jobList.status === "Urgent" ? (
          <Col>
            <Row className="elements-text-danger">
              <Col
                className="edit-title-padding"
                style={{ paddingLeft: "0em" }}
              >
                <TextElement name={jobList.job_no} />
              </Col>
              <Col className="edit-title-padding">
                <TextElement name={jobList.created_at} />
              </Col>
              <Col className="edit-title-padding">
                <TextElement name={jobList.company_name} />
              </Col>
              <Col
                className="edit-title-padding"
                style={{ paddingRight: "0em" }}
              >
                <TextElement name={jobList.status} />
              </Col>
            </Row>
          </Col>
        ) : (
          <Col>
            <Row className="elements-text-safe">
              <Col
                className="edit-title-padding"
                style={{ paddingLeft: "0em" }}
              >
                <TextElement name={jobList.job_no} />
              </Col>
              <Col className="edit-title-padding">
                <TextElement name={jobList.created_at} />
              </Col>
              <Col className="edit-title-padding">
                <TextElement name={jobList.company_name} />
              </Col>
              <Col
                className="edit-title-padding"
                style={{ paddingRight: "0em" }}
              >
                <TextElement name={jobList.status} />
              </Col>
            </Row>
          </Col>
        )}
        <Col className="col-3" />
      </Row>
      <Row className="editprocess-processRow">
        <Row className="editprocess-processRow1 ">
          <Col className="title-padding">
            <BodyProcess title="Dismantle" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Quotation" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Pretest" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Customer Confirmation" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Purchasing" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Job In Progress" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="QC Inspection" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Report" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Billing" />
          </Col>
          <Col className="title-padding">
            <BodyProcess title="Delivery" />
          </Col>
        </Row>
        <Row className="editprocess-processRow2">
          <div className="edit-process-line"></div>
          <Col className="element-button">
            <SignElement
              value={jobList.dismantle ? jobList.dismantle.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.quotation ? jobList.quotation.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.pretest ? jobList.pretest.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={
                jobList.customer_confirmation
                  ? jobList.customer_confirmation.status
                  : ""
              }
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.purchasing ? jobList.purchasing.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={
                jobList.job_in_progress ? jobList.job_in_progress.status : ""
              }
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.qc_inspection ? jobList.qc_inspection.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement value={jobList.report ? jobList.report.status : ""} />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.billing ? jobList.billing.status : ""}
            />
          </Col>
          <Col className="element-button">
            <SignElement
              value={jobList.delivery ? jobList.delivery.status : ""}
            />
          </Col>
        </Row>
      </Row>
      <EditProcessTab
        customID={custID}
        refreshStatus={refreshStatus}
        setRefreshStatus={setRefreshStatus}
        jobList={jobList}
        refreshPage={refreshPage}
        setRefreshPage={setRefreshPage}
        getJobList={getJobList}
      />
    </Container>
  );
};

export default MainEdit;

{
  /* <Row className="editprocess-processRow3">
        <Col>
          {!_.isEmpty(jobList.dismantle) ? (
            jobList.dismantle.time && jobList.dismantle.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.dismantle.time}</div>
                <div>{jobList.dismantle.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.quotation) ? (
            jobList.quotation.time && jobList.quotation.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.quotation.time}</div>
                <div>{jobList.quotation.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.pretest) ? (
            jobList.pretest.time && jobList.pretest.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.pretest.time}</div>
                <div>{jobList.pretest.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.customer_confirmation) ? (
            jobList.customer_confirmation.time &&
            jobList.customer_confirmation.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.customer_confirmation.time}</div>
                <div>{jobList.customer_confirmation.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.purchasing) ? (
            jobList.purchasing.time && jobList.purchasing.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.purchasing.time}</div>
                <div>{jobList.purchasing.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.job_in_progress) ? (
            jobList.job_in_progress.time &&
            jobList.job_in_progress.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.job_in_progress.time}</div>
                <div>{jobList.job_in_progress.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.qc_inspection) ? (
            jobList.qc_inspection.time && jobList.qc_inspection.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.qc_inspection.time}</div>
                <div>{jobList.qc_inspection.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.report) ? (
            jobList.report.time && jobList.report.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.report.time}</div>
                <div>{jobList.report.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.billing) ? (
            jobList.billing.time && jobList.billing.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.billing.time}</div>
                <div>{jobList.billing.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
        <Col>
          {!_.isEmpty(jobList.delivery) ? (
            jobList.delivery.time && jobList.delivery.date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{jobList.delivery.time}</div>
                <div>{jobList.delivery.date}</div>
              </React.Fragment>
            )
          ) : (
            ""
          )}
        </Col>
      </Row> */
}
