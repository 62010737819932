//React element
import React, { useState, useEffect } from "react";
import { Container, Row, FormText } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Form element
import LogoPic from "../../assets/logoV2.png";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikForm from "./../common/formikForm";

//Api integration
import authService from "./../services/auth-service";
// import renderService from "./../services/render-service";

async function onSubmit(values, navigate) {
  try {
    const response = await authService.login(values.username, values.password);

    if (response) {
      toast.configure();
      toast("Succesfully Login", {
        type: "success",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        draggableDirection: "x",
      });
      navigate("/main");
    }
  } catch (ex) {
    if (ex && Object.keys(ex).length > 0) {
      const error = ex.response.values;
    }
    if (ex.response.data.data.error == "Invalid username or password") {
      toast.configure();
      toast("Invalid username or password", {
        type: "error",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        draggableDirection: "x",
      });
    }
  }
}

const Login = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ username: "", password: "" });
  // const [accountStatus, setAccountStatus] = useState({});

  const schema = Yup.object({
    username: Yup.string().required("* Username required!"),
    password: Yup.string().required("* Password required!"),
  });

  // const getAccountStatus = async () => {
  //   const response = await renderService.accountStatus();

  //   console.log("Account status", response);

  //   setAccountStatus(response.data);
  // };

  // useEffect(() => {
  //   getAccountStatus().catch((e) => {
  //     console.log(e);
  //   });
  // }, []);

  return (
    <Container fluid className="login-background">
      <Row className="login-form">
        <Row className="login-form-header g-0"></Row>
        <Row className="login-form-body g-0">
          <img src={LogoPic} alt="" className="login-logo" />
          <Formik
            initialValues={data}
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values, navigate);
              resetForm({ values: data });
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="login-formik-form">
                {FormikForm.renderInput("username", "Username")}
                {FormikForm.renderInput("password", "Password", "password")}
                {FormikForm.renderButton("LOG IN", isSubmitting, isValid)}
              </Form>
            )}
          </Formik>
          <FormText className="login-footer">
            Powered by <span>techpole.co</span>
          </FormText>
        </Row>
      </Row>
    </Container>
  );
};

export default Login;
