import axios from "axios";
import logger from "./log-service";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  let inactived = true;
  let apparatusError = true;

  // const authError = error.response && error.response.status === 401;
  const authError =
    error.response &&
    error.response.data.error === "Invalid username or password";
  const activateError =
    error.response && error.response.data.error === "Unauthenticated.";
  if (error.response) {
    if (error.response.data) {
      inactived =
        error.response &&
        error.response.data.message === "账号已锁，请联络客服";
      if (error.response.data.errors) {
        apparatusError = error.response && error.response.data.errors.id;
      }
    }
  }

  //   const exptectedError = error.response && error.response.status >= 404;
  const unexpectedError = error.response.status === 500;

  // console.log("http response", error.response.data);

  if (authError) {
    localStorage.clear();
    // window.location.reload();
  }

  if (activateError) {
    // console.log("activeError");
    localStorage.clear();
    window.location.reload();
  }

  if (inactived) {
    toast.configure();
    toast("This account has been inactivated", {
      type: "error",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      draggableDirection: "x",
    });
  }

  if (apparatusError) {
    if (error.response.data.errors) {
      toast.configure();
      toast(error.response.data.errors.id, {
        type: "error",
        position: "top-center",
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        draggableDirection: "x",
      });
    }
  }

  if (unexpectedError) {
    toast.configure();
    toast("Something went wrong... Please contact customer support", {
      type: "info",
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      draggableDirection: "x",
    });
    logger.log(error);
  }
  return Promise.reject(error);
});

function setToken(token) {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
    Accept: `application/json`,
  };
}

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setToken,
};

export default httpService;
