//React element
import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import _ from "lodash";

//Tab content
import EditTab from "./editProcessTabEdit";
import RemarkTab from "./editProcessTabRemark";
import ViewTab from "./editProcessTabView";
import PurchaseTab from "./editProcessTabPurchase";

//Api integration
import renderService from "./../services/render-service";
import JobList from "./jobListing";
import { useSearchParams } from "react-router-dom";

const EditProcessTab = ({
  customID,
  refreshStatus,
  setRefreshStatus,
  jobList,
  refreshPage,
  setRefreshPage,
  getJobList,
}) => {
  const [jobInfo, setJobInfo] = useState({});
  const [activeTab, setActiveTab] = useState(false);
  const [dismantleData, setDismantleData] = useState({});
  const [quotationData, setQuotationData] = useState({});
  const [pretestData, setPretestData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [purchasingData, setPurchasingData] = useState({});
  const [jobData, setJobData] = useState({});
  const [qcData, setQcData] = useState({});
  const [reportData, setReportData] = useState({});
  const [billingData, setBillingData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [refreshPurchaseSave, setRefreshPurchaseSave] = useState(false);
  const [refreshMedia, setRefreshMedia] = useState(false);
  const [ searchParams,setSearchParams] = useSearchParams()

  // console.log("seacrchParams",searchParams.get("tabs"))
  // const getJobInfo = async () => {
  //   const response = await renderService.jobInfo(customID);
  //   setJobInfo(response.data);
  //   setDismantleData(response.data.dismantle.data);
  //   setQuotationData(response.data.quotation.data);
  //   setPretestData(response.data.pretest.data);
  //   setCustomerData(response.data.customer_confirmation.data);
  //   setPurchasingData(response.data.purchasing.data);
  //   setJobData(response.data.job_in_progress.data);
  //   setQcData(response.data.qc_inspection.data);
  //   setReportData(response.data.report.data);
  //   setBillingData(response.data.billing.data);
  // };

  // console.log("Tab puschaseList", purchaseList);
  // console.log("Main Tab refresh", refresh);

  useEffect(() => {
    setJobInfo(jobList);
    setDismantleData(jobList.dismantle.data);
    setQuotationData(jobList.quotation.data);
    setPretestData(jobList.pretest.data);
    setCustomerData(jobList.customer_confirmation.data);
    setPurchasingData(jobList.purchasing.data);
    setJobData(jobList.job_in_progress.data);
    setQcData(jobList.qc_inspection.data);
    setReportData(jobList.report.data);
    setBillingData(jobList.billing.data);
  });

  useEffect(() => {
    // getJobInfo().catch((e) => {
    //   // console.log(e);
    // });
    if (refresh || refreshMedia) {
      setRefreshStatus(!refreshStatus);
    }
  }, [refresh, refreshMedia]);

  // useEffect(() => {
  //   getJobInfo().catch((e) => {
  //     // console.log(e);
  //   });
  // }, [refreshMedia]);

  return (
    <div className="body-editprocessTab">
      {/* <a href="/edit?tabs=dismantle">Dismantle</a>
      <a href="/edit?tabs=quotation">Quotation</a>
      <a href="/edit?tabs=pretest">Pretest</a>
      <a href="/edit?tabs=customer_confirmation">Customer Confirmation</a>
      <a href="/edit?tabs=purchasing">Purchasing</a>
      <a href="/edit?tabs=job_in_progress">Job In Progress</a>
      <a href="/edit?tabs=qc_inspection">QC Inspection</a>
      <a href="/edit?tabs=report">Report</a>
      <a href="/edit?tabs=billing">Billing</a> */}
            
      <Tabs
        id="editProcess"
        transition={false}
        defaultActiveKey="dismantle"
        className="editprocessTab-Tabs nav-fill"
        onClick={() => {
          setActiveTab(!activeTab);
          // window.open("someLink","_blank")
        }}
      >
        {!_.isEmpty(dismantleData) ? (
          <Tab eventKey="dismantle" title="Dismantle">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.dismantle) ? (
                  <EditTab
                    data={jobInfo.dismantle}
                    name="dismantle"
                    editName="dismantle"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.dismantle) ? (
                  <RemarkTab data={jobInfo.dismantle} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(quotationData) ? (
          <Tab eventKey="quotation" title="Quotation">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.quotation) ? (
                  <EditTab
                    data={jobInfo.quotation}
                    name="quotation"
                    editName="quotation"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshMedia={refreshMedia}
                    setRefreshMedia={setRefreshMedia}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                <RemarkTab data={jobInfo.quotation} />
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(pretestData) ? (
          <Tab eventKey="pretest" title="Pretest">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.pretest) ? (
                  <EditTab
                    data={jobInfo.pretest}
                    name="pretest"
                    editName="pretest"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshMedia={refreshMedia}
                    setRefreshMedia={setRefreshMedia}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.pretest) ? (
                  <RemarkTab data={jobInfo.pretest} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(customerData) ? (
          <Tab eventKey="customer" title="Customer Confirmation">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.customer_confirmation) ? (
                  <EditTab
                    data={jobInfo.customer_confirmation}
                    name="customer"
                    editName="customer confirmation"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.customer_confirmation) ? (
                  <RemarkTab data={jobInfo.customer_confirmation} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(purchasingData) ? (
          <Tab eventKey="purchasing" title="Purchasing">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.purchasing) ? (
                  <EditTab
                    data={jobInfo.purchasing}
                    name="purchasing"
                    editName="purchasing"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPurchaseSave={refreshPurchaseSave}
                    setRefreshPurchaseSave={setRefreshPurchaseSave}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.purchasing) ? (
                  <RemarkTab data={jobInfo.purchasing} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <PurchaseTab
                customerID={customID}
                refresh={refresh}
                refreshPurchaseSave={refreshPurchaseSave}
              />
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(jobData) ? (
          <Tab eventKey="job" title="Job In Progress">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.job_in_progress) ? (
                  <EditTab
                    data={jobInfo.job_in_progress}
                    name="job"
                    editName="job in progress"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.job_in_progress) ? (
                  <RemarkTab data={jobInfo.job_in_progress} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(qcData) ? (
          <Tab eventKey="qc" title="QC Inspection">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.qc_inspection) ? (
                  <EditTab
                    data={jobInfo.qc_inspection}
                    name="qc"
                    editName="qc inspection"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.qc_inspection) ? (
                  <RemarkTab data={jobInfo.qc_inspection} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(reportData) ? (
          <Tab eventKey="report" title="Report">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.report) ? (
                  <EditTab
                    data={jobInfo.report}
                    name="report"
                    editName="report"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshMedia={refreshMedia}
                    setRefreshMedia={setRefreshMedia}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.report) ? (
                  <RemarkTab data={jobInfo.report} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
        {!_.isEmpty(billingData) ? (
          <Tab eventKey="billing" title="Billing">
            <Row>
              <Col>
                {!_.isEmpty(jobInfo.billing) ? (
                  <EditTab
                    data={jobInfo.billing}
                    name="billing"
                    editName="billing"
                    activeTab={activeTab}
                    customerID={customID}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    refreshPage={refreshPage}
                    setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo.billing) ? (
                  <RemarkTab data={jobInfo.billing} />
                ) : (
                  ""
                )}
              </Col>
              <Col>
                {!_.isEmpty(jobInfo) ? (
                  <ViewTab
                    data={jobInfo}
                    getJobList={getJobList}
                    // refreshPage={refreshPage}
                    // setRefreshPage={setRefreshPage}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Tab>
        ) : (
          ""
        )}
      </Tabs>
    </div>
  );
};

export default EditProcessTab;
