//React element
import React from "react";
import { FormText } from "react-bootstrap";

const TitleComponent = ({ title }) => {
  return (
    <React.Fragment>
      <FormText className="custom-text">{title}</FormText>
    </React.Fragment>
  );
};

export default TitleComponent;
