//React element
import React from "react";

const PlainButton = ({ label, type, ...rest }) => {
  return (
    <span className="custom-plainButton">
      <button className="plainButton" type="button" {...rest}>
        {label}
      </button>
    </span>
  );
};

export default PlainButton;
