//React element
import React, { useEffect, useState } from "react";
import FormikForm from "../formikForm";
import { toast } from "react-toastify";

//Elements
import Buttons from "../elements/plainButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Modal, Container, Row, Col } from "react-bootstrap";
import RUsure from "./ruSureBox";

//API
import authService from "../../services/auth-service";

//Svg
import SvgImage from "../../../assets/x-circle.svg";

const ChangePassword = ({ show, closeHandler }) => {
  // const [rUsure, setRUsure] = useState({});
  const [data, setData] = useState({
    password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const schema = Yup.object({
    password: Yup.string().required("Required!"),
    new_password: Yup.string().required("Required!"),
    confirm_new_password: Yup.string()
      .required("Required!")
      .oneOf([Yup.ref("new_password"), null], "Must match with new password"),
  });

  async function submitHandler(values, closeHandler, setSubmitting) {
    try {
      const response = await authService.ChangePassword(
        values.password,
        values.new_password,
        values.confirm_new_password
      );
      if (response) {
        toast.configure();
        toast("Change Password Success!", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
        closeHandler();
      }
    } catch (ex) {
      if (ex.response.data.errors.password == "Wrong password") {
        toast.configure();
        toast("Wrong: Old Password.", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
      setSubmitting(false);
    }
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        <img
          src={SvgImage}
          variant="default"
          size="sm"
          onClick={() => closeHandler()}
        />
      </div>
      <Modal.Header className="modalbox-header-createdit">
        <Modal.Title>Change Password Setting</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={data}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          submitHandler(values, closeHandler, setSubmitting);
        }}
      >
        {({ isSubmitting, isValid, handleReset, errors }) => (
          <Form>
            <Container>
              <Row className="modalbox-jobox-row">
                <Col className="col-6 modalbox-cebox-listName">
                  Old Password
                </Col>
                <Col className="col-6 modalbox-cebox-input">
                  {FormikForm.renderPlainText("password", "", errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-6 modalbox-cebox-listName">
                  New Password
                </Col>
                <Col className="col-6 modalbox-cebox-input">
                  {FormikForm.renderPlainText("new_password", "", errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-6 modalbox-cebox-listName">
                  Confirm Password
                </Col>
                <Col className="col-6 modalbox-cebox-input">
                  {FormikForm.renderPlainText(
                    "confirm_new_password",
                    "",
                    errors
                  )}
                </Col>
              </Row>
              <Row className="modalbox-cebox-buttonRow">
                <Col className="modalbox-cebox-button">
                  <Buttons
                    label={"Clear"}
                    onClick={() => {
                      handleReset();
                    }}
                  />
                </Col>
                <Col className="modalbox-cebox-button">
                  {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
