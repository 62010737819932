//React element
import React, { lazy, Suspense, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import _ from "lodash";

//Title
import TitleRow from "../common/titleJob";
import ProcessRow from "../common/titleProcess";

//API integration
import renderService from "../services/render-service";

//Body
import JobList from "../common/jobListing";

const Main = ({ setExportData, quickSearch, branchNaming }) => {
  const prevPage = localStorage.getItem("prevPath");
  const [jobList, setJobList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [exportID, setExportID] = useState([]);
  const [totalListLength, setTotalListLength] = useState(0);
  const [showEmpty, setShowEmpty] = useState(false);
  const [totalID, setTotalID] = useState({});
  const [quickSearchList, setQuickSearchList] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [tasksPerPage] = useState(15);
  const [prevActivePage, setPrevActivePage] = useState(
    parseInt(localStorage.getItem("prevActivePage"))
  );

  const getJobList = async () => {
    setJobList([]);
    let start = 0;

    if (prevPage != null) {
      start = prevActivePage * 15;
    }
    const response = await renderService.jobListing(
      quickSearch,
      branchNaming,
      start
    );

    if (response) {
      let tempJob = response.data.aaData.map((listing) => {
        return { ...listing, isChecked: false };
      });
      let tempID = response.data.aaData.map((listing) => {
        return listing.id;
      });
      setJobList(tempJob);
      setExportID(tempID);
      if (quickSearch) {
        setPageCount(Math.ceil(response.data.iTotalRecords / tasksPerPage));
      } else {
        setPageCount(Math.ceil(response.data.iTotalRecords / tasksPerPage));
      }
      setTotalListLength(response.data.iTotalRecords);
      if (response.data.aaData.length === 0) {
        setShowEmpty(true);
      }
    } else {
      setShowEmpty(true);
    }
  };

  const getTotalJobList = async () => {
    const response = await renderService.totalJobListing();
  };

  const handleChange = (e) => {
    const { id, checked } = e.target;
    // let tempUser = jobList.map((listing) => {
    //   return { ...listing, isChecked: checked };
    // });
    // setJobList(tempUser);
    if (id === "allSelect") {
      let tempUser = jobList.map((listing) => {
        return { ...listing, isChecked: checked };
      });
      setJobList(tempUser);
    } else {
      // if(checked )
      // tempC =
      let tempP = jobList.map((listing) =>
        listing.id == id ? { ...listing, isChecked: checked } : listing
      );
      setJobList(tempP);
    }
  };

  const pageChanges = async (data) => {
    localStorage.setItem("prevActivePage", data.selected);
    let currentPage = data.selected * 15;

    const nextPage = await renderService.nextJobListing(
      quickSearch,
      currentPage
    );

    let tempID = nextPage.data.aaData.map((listing) => {
      return listing.id;
    });
    setJobList(nextPage.data.aaData);
    setExportID(tempID);
  };

  const getQuickSearchList = async () => {
    const response = await renderService.quickSearchList(quickSearch);

    // setQuickSearchList(response.data.aaData);
    setJobList(response.data.aaData);
  };

  useEffect(() => {
    // console.log("prevActivePage")
    setPrevActivePage(localStorage.getItem("prevActivePage"));
    getJobList();
  }, [quickSearch, branchNaming]);

  useEffect(() => {
    if (exportID && isCheck === false) {
      setExportData(exportID);
    }
  }, [exportID, isCheck]);

  useEffect(() => {
    if (prevPage !== null) {
      console.log("!== null");
      localStorage.removeItem("prevPath");
    }

    return () => {
      // localStorage.setItem("prevPath",window.location.pathname)
      // console.log("prevPath",prevPage)
      // console.log("prevPath local",localStorage.getItem("prevPath"))
      // localStorage.removeItem("prevPath")
      if (window.location.pathname !== "/edit") {
        localStorage.removeItem("prevActivePage");
      }
    };
  }, []);

  return (
    <Container fluid className="body-main">
      <Row className="body-titleRow">
        <Col className="col-6" style={{ alignSelf: "center" }}>
          <TitleRow
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            listLength={totalListLength}
            setTotalID={setTotalID}
            pageName="main"
            setExportData={setExportData}
          />
        </Col>
        <Col className="col-6">
          <ProcessRow />
        </Col>
      </Row>

      <JobList
        jobList={jobList}
        getJobListMain={getJobList}
        handleChange={handleChange}
        showEmpty={showEmpty}
      />
      {/* {_.get(quickSearchList) ? (
        <JobList jobList={quickSearchList} handleChange={handleChange} />
      ) : (
        <JobList jobList={jobList} handleChange={handleChange} />
      )} */}
      {/* {console.log("page count", pageCount)} */}
      {jobList && (
        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          breakLabel={"..."}
          pageCount={pageCount}
          // pageCount={Math.ceil(jobList.length / tasksPerPage)}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={pageChanges}
          containerClassName={"pagination justify-content-end"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          initialPage={prevActivePage}
        />
      )}
    </Container>
  );
};

export default Main;
