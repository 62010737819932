//React element
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Title
import TitleRow from "../common/titleJob";
import ProcessRow from "../common/titleProcess";

//Body
import JobList from "../common/jobListing";
import JobListTime from "../common/jobListTimeline";
import SettingList from "../common/settingListing";

//Pop up
import Modalbox from "./../common/popup/modalbox";

const Template = () => {
  const [joblistTime, setJoblistTime] = useState(false);
  const [show, setShow] = useState(false);

  const customClose = () => {
    setShow(false);
  };

  return (
    <Container fluid className="body-main">
      <Modalbox show={show} closeHandler={customClose} />

      <Row className="body-titleRow">
        <Col className="body-titleRow-col1 title-padding">
          <TitleRow />
        </Col>
        <Col className="title-padding">
          <ProcessRow />
        </Col>
      </Row>
      <div
        className="body-joblistRow"
        onClick={() => setJoblistTime(!joblistTime)}
      >
        <JobList />
        {joblistTime && <JobListTime />}
      </div>
      <div>
        <SettingList />
      </div>
      <div
        onClick={() => {
          setShow(true);
        }}
        style={{ backgroundColor: "white", marginTop: 20 }}
      >
        Try me
      </div>
    </Container>
  );
};

export default Template;
