//React element
import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";

const RemarkTab = ({ data }) => {
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (data) {
      setInfo(data.remark);
    }
  });

  return (
    <Container className="editprocessTabcontent-container">
      {info.length > 0 &&
        info.map((listing, count) => {
          return (
            <div key={count}>
              <React.Fragment>
                <Row className="editprocessTabRemark-row1">{listing.name}</Row>
                <Row className="editprocessTabRemark-row2">
                  {listing.remark}
                </Row>
              </React.Fragment>
            </div>
          );
        })}
    </Container>
  );
};

export default RemarkTab;
