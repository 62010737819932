//React element
import React from "react";
import { Modal, Button } from "react-bootstrap";

const Modalbox = ({ show, closeHandler }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      // className="popup-modalbox"
      // dialogClassName="popup-modalbox"
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        <Button
          variant="default"
          className="modalbox-Xbutton"
          size="sm"
          onClick={() => closeHandler()}
        >
          X
        </Button>
      </div>
      <Modal.Header className="modalbox-header">
        <Modal.Title>I am Modal Header</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbox-body">I am Modal Body</Modal.Body>
    </Modal>
  );
};

export default Modalbox;
