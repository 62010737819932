//React element
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import _, { isEmpty } from "lodash";
import moment from "moment";
//Elements
import TextElement from "./elements/text";
import SignElement from "./elements/status";
import JobListTime from "../common/jobListTimeline";
import SvgGear from "./../../assets/gear.svg";

//View form
import ViewTabForm from "./editProcessTabViewForm";

//API integration
import renderService from "../services/render-service";

//Editable icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePen } from "@fortawesome/free-solid-svg-icons";

const JobList = ({ jobList, getJobListMain, handleChange, showEmpty }) => {
  const [joblistTime, setJoblistTime] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState({});
  const position = localStorage.getItem("position");

  const customClose = () => {
    setShow(false);
    setDetailData({});
  };

  const getJobList = async (id) => {
    const response = await renderService.jobInfo(id);
    setDetailData(response.data);
  };

  useEffect(() => {}, [showEmpty]);
  // useEffect(() => {
  //   getJobListMain();
  // }, show);
  // console.log("jobList", jobList);

  return (
    <Container fluid className="body-joblist-container">
      {!_.isEmpty(detailData) ? (
        <ViewTabForm
          show={show}
          setShow={setShow}
          closeHandler={customClose}
          customerID={_.get(detailData, "id")}
          selectValue={detailData}
          getJobList={getJobList}
          getJobListMain={getJobListMain}
        />
      ) : (
        ""
      )}

      {!_.isEmpty(jobList) ? (
        jobList.length > 0 &&
        jobList.map((listing, count = []) => {
          return (
            <div key={listing.id}>
              <Row className="body-joblistRow">
                {listing.job_status === "Urgent" ? (
                  <React.Fragment>
                    <Col className="d-flex textAlign-center">
                      <Col
                        className="width8"
                        style={{ backgroundColor: "#2A2B2C" }}
                      >
                        <FontAwesomeIcon
                          id={listing.id}
                          icon={faSquarePen}
                          className="icon-squarePen"
                          onClick={() => {
                            setShow(true);
                            getJobList(listing.id);
                          }}
                        />
                        {/* <input
                                type="button"
                                className="form-check-input"
                                id={listing.id}
                                // checked={listing.isChecked}
                                // onChange={handleChange}
                                onClick={() => {
                                  setShow(true);
                                  getJobList(listing.id);
                                }}
                              /> */}
                      </Col>
                      {/* Additional column for super admin */}
                      <Col style={{ display: "flex" , background: "#EF7139", borderRadius: "5px" }}>
                        {position === "super admin" ? (
                          <Col className="width8">
                            <TextElement name={listing.branch} />
                          </Col>
                        ) : (
                          ""
                        )}
                        {/*  */}
                        <Col
                          className="width12"
                          onClick={() => {
                            navigate("/edit", {
                              state: {
                                custData: listing.id,
                              },
                            });
                          }}
                        >
                          <TextElement name={listing.job_number} />
                        </Col>
                        <Col className="width17">
                          <TextElement name={moment(listing.start_date).format("yyyy-MM-DD")} />
                        </Col>
                        {/* Switch when super admin is ready */}
                        {position !== "super admin" ? (
                          <Col className="width40">
                            <TextElement name={listing.customer_name} />
                          </Col>
                        ) : (
                          <Col className="width32">
                            <TextElement name={listing.customer_name} />
                          </Col>
                        )}
                        {/* <Col className="col-4 ">
                          <TextElement
                            name={listing.customer_name.substring(0, 18)}
                          />
                        </Col> */}
                        {/*  */}
                        <Col className="width9">
                          <TextElement name={listing.job_status} />
                        </Col>
                        <Col className="width14">
                          <TextElement name={listing.follow_up_by} />
                        </Col>
                      </Col>
                    </Col>
                    <Col
                      className="d-flex textAlign-center elements-text-danger"
                      onClick={() => {
                        setJoblistTime(listing.id);
                      }}
                    >
                      <Col className="col-1">
                        <SignElement
                          value={listing.dismantle_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.quotation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.pretest_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.customer_confirmation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.purchasing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.job_in_progress_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.qc_inspection_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.report_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.billing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.delivery_status}
                          status={listing.job_status}
                        />
                      </Col>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col className="d-flex textAlign-center">
                      <Col
                        className="width8"
                        style={{ backgroundColor: "#2A2B2C" }}
                      >
                        <FontAwesomeIcon
                          id={listing.id}
                          icon={faSquarePen}
                          className="icon-squarePen"
                          onClick={() => {
                            setShow(true);
                            getJobList(listing.id);
                          }}
                        />
                      </Col>
                      <Col style={{ display: "flex" , background: "none", borderRadius: "5px" }}>
                      {position === "super admin" ? (
                        <Col className="width8">
                          <TextElement name={listing.branch} />
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col
                        className="width12"
                        onClick={() => {
                          navigate("/edit", {
                            state: {
                              custData: listing.id,
                            },
                          });
                        }}
                      >
                        <TextElement name={listing.job_number} />
                      </Col>
                      <Col className="width17">
                        <TextElement name={moment(listing.start_date).format("yyyy-MM-DD")} />
                      </Col>
                      {position !== "super admin" ? (
                        <Col className="width40">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      ) : (
                        <Col className="width32">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      )}
                      <Col className="width9">
                        <TextElement name={listing.job_status} />
                      </Col>
                      <Col className="width14">
                        <TextElement name={listing.follow_up_by} />
                      </Col>
                      </Col>
                    </Col>
                    <Col
                      className="d-flex textAlign-center elements-text-safe"
                      onClick={() => {
                        setJoblistTime(listing.id);
                      }}
                    >
                      <Col className="col-1">
                        <SignElement
                          value={listing.dismantle_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.quotation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.pretest_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.customer_confirmation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.purchasing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.job_in_progress_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.qc_inspection_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.report_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.billing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.delivery_status}
                          status={listing.job_status}
                        />
                      </Col>
                    </Col>
                  </React.Fragment>
                )}
                {joblistTime == listing.id && (
                  <JobListTime
                    data={jobList}
                    keyID={listing.id}
                    index={count}
                  />
                )}
              </Row>
            </div>
          );
        })
      ) : showEmpty ? (
        <span className="empty-page">No Data Available</span>
      ) : (
        <React.Fragment>
          <img src={SvgGear} className="loading-gear" />
        </React.Fragment>
      )}
    </Container>
  );
};

export default JobList;
