//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

//Form element
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikForm from "./formikForm";
import SelectV3 from "./elements/selectV3";

//Elements
import Buttons from "./../common/elements/plainButton";

//Pop up
import RUsure from "./popup/ruSureBox";

//Svg
import SvgImage from "../../assets/x-circle.svg";

//API integration
import authService from "../services/auth-service";
import renderService from "../services/render-service";
import axios from "axios";

const ViewTabForm = ({
  show,
  setShow,
  showviewTabForm,
  closeHandler,
  customerID,
  selectValue,
  getJobList,
  getJobListMain,
  getJobListEdit,
}) => {
  const [data, setData] = useState({
    job_no: selectValue.job_no ? selectValue.job_no : "",
    pickDate: new Date(),
    // company: selectValue.company_id,
    company: selectValue.company_id,
    status: selectValue.status.toLowerCase(),
    apparatus: selectValue.apparatus_type_id,
    rate: selectValue.rated_unit,
    power: selectValue.power_unit_id,
    pic: selectValue.pic_receive_id,
    gate: selectValue.gate_pass_no ? selectValue.gate_pass_no : "",
    follow: selectValue.follow_up_by,
    remark: selectValue.remark ? selectValue.remark : "",
  });

  // const [setPowerUnitData]
  const [dateTime, setDateTime] = useState(new Date());
  // const [showBox, setShowBox] = useState(false);
  // const [confirmState, setConfirmState] = useState(false);
  // const [rUsure, setRUsure] = useState({});
  const [optionsCS, setOptionsCS] = useState({});
  const [optionsApparatus, setOptionsApparatus] = useState({});
  const [optionsPowerUnit, setOptionsPowerUnit] = useState({});
  const [optionsPIC, setOptionsPIC] = useState({});
  const [optionsFollow, setOptionsFollow] = useState({});
  const navigate = useNavigate();
  const position = localStorage.getItem("position");

  // if (confirmState === true) {
  //   onSubmit(rUsure, customerID);
  // }
  // console.log("data", data);
  useEffect(() => {
    setData({
      job_no: selectValue.job_no,
      pickDate: new Date(),
      // company: selectValue.company_id,
      company: selectValue.company_id,
      status: selectValue.status.toLowerCase(),
      apparatus: selectValue.apparatus_type_id,
      rate: selectValue.rated_unit,
      power: selectValue.power_unit_id,
      pic: selectValue.pic_receive_id,
      gate: selectValue.gate_pass_no ? selectValue.gate_pass_no : "",
      follow: selectValue.follow_up_by,
      remark: selectValue.remark ? selectValue.remark : "",
    });
  }, [selectValue]);

  async function onSubmit(values, setSubmitting, closeHandler) {
    try {
      const response = await authService.updateJob(
        values.job_no,
        customerID,
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss"),
        values.company,
        values.status,
        values.apparatus,
        values.rate,
        values.power,
        values.pic,
        values.gate,
        values.remark,
        values.follow
      );

      if (response) {
        toast.configure();
        toast("Success", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
        setShow(false);
        if (showviewTabForm === "viewTabForm") {
          getJobListEdit();
        } else {
          getJobList();
          getJobListMain();
        }
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const error = ex.response.values;
        toast.configure();
        toast("Wrong Input", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
      setSubmitting(false);
    }
  }

  // const customClose = () => {
  //   setShowBox(false);
  // };

  async function onDelete(id) {
    const response = await authService.deleteJob(id);
    if (response) {
      navigate("/main");
      window.location.reload();
    }
  }

  const matchApparatus = (e) => {
    getOptionsPowerUnit(e);
  };

  const getOptionsCS = async () => {
    const response = await renderService.optionsCustomer();
    setOptionsCS(response.data.data);
  };

  const getOptionsApparatus = async () => {
    const response = await renderService.optionsApparatus();
    setOptionsApparatus(response.data.data);
  };

  const getOptionsPowerUnit = async (apparatusID) => {
    const response = await renderService.optionsPowerUnit(apparatusID);
    setOptionsPowerUnit(response.data);
  };

  const getOptionsPIC = async () => {
    const response = await renderService.optionsPICreceive();
    setOptionsPIC(response.data.data);
  };

  const getOptionsFollow = async () => {
    const response = await renderService.optionsFollow();
    setOptionsFollow(response.data.data);
  };

  useEffect(() => {
    // console.log("view form selectedValue", selectValue);
    if (show) {
      getOptionsCS().catch((e) => {
        // console.log(e);
      });
      getOptionsApparatus().catch((e) => {
        // console.log(e);
      });
      getOptionsPowerUnit(selectValue.apparatus_type_id).catch((e) => {
        // console.log(e);
      });
      getOptionsPIC().catch((e) => {
        // console.log(e);
      });
      getOptionsFollow().catch((e) => {
        // console.log(e);
      });
    }
  }, [show]);

  const statusOptions = [
    { label: "Please Select-", id: 0 },
    { label: "Normal", id: "normal" },
    { label: "Urgent", id: "urgent" },
  ];

  const schema = Yup.object({
    company: Yup.string().required("Required!"),
    status: Yup.string().required("Required!"),
    apparatus: Yup.string().required("Required!"),
    rate: Yup.string().required("Required!"),
    power: Yup.string().required("Required!"),
    pic: Yup.string().required("Required!"),
    // gate: Yup.string().required("Required!"),
    follow: Yup.string().required("Required!"),
    // remark: Yup.string().required("Required!"),
  });

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        <img
          src={SvgImage}
          variant="default"
          size="sm"
          onClick={() => {
            closeHandler();
          }}
        />
      </div>
      <Modal.Header
        className="modalbox-header-createdit"
        style={{ margin: "0em 20em" }}
      >
        <Modal.Title>Job Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbox-body">
        {/* <RUsure
          show={showBox}
          setConfirmState={setConfirmState}
          closeHandler={customClose}
          value={rUsure}
        /> */}
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          initialValues={data}
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
            // setShowBox(true);
            // setRUsure(values);
          }}
        >
          {({ isSubmitting, isValid, setFieldValue, errors }) => (
            <Form>
              {
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">Job No:</Col>
                  <Col className="col-7 modalbox-cebox-list">
                    {position === "branch admin" ? (
                      FormikForm.renderPlainText("job_no", "", errors)
                    ) : (
                      <>
                        <div className="custom-text">
                          <Field
                            className="plainText"
                            name={"job_no"}
                            type={"text"}
                            readOnly={true}
                          />
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              }
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">Date:</Col>
                <Col className="col-7 color-black">
                  <DateTimePicker
                    onChange={(e) => {
                      {
                        setFieldValue("pickDate", e);
                        setDateTime(e);
                      }
                    }}
                    value={dateTime}
                    name="pickDate"
                    className="modalbox-jobox-datetime"
                    format="y-MM-dd hh:mm:ss"
                    clearIcon={null}
                    disabled
                  />
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">
                  Company Name:
                </Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderSelectV2("company", optionsCS, errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">Status:</Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderSelect("status", statusOptions, errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">
                  Aparatus Type:
                </Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  <SelectV3
                    name={"apparatus"}
                    options={optionsApparatus}
                    onchange={(e) => {
                      setFieldValue("apparatus", e.target.value);
                      matchApparatus(e.target.value);
                      setFieldValue("power", "");
                    }}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">Rated Unit:</Col>
                <Col className="col-7 modalbox-cebox-list">
                  {FormikForm.renderPlainText("rate", "", errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">Power Unit:</Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderSelectV2("power", optionsPowerUnit, errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">
                  PIC Receive:
                </Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderSelectV2("pic", optionsPIC, errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">
                  Gate Pass NO:
                </Col>
                <Col className="col-7 modalbox-cebox-list">
                  {FormikForm.renderPlainText("gate", "", errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">
                  Follow Up By:
                </Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderSelectV2("follow", optionsFollow, errors)}
                </Col>
              </Row>
              <Row className="modalbox-jobox-row">
                <Col className="col-5 modalbox-cebox-listName">Remark:</Col>
                <Col className="col-7 editprocessTabEditForm-title">
                  {FormikForm.renderTextarea("remark", errors)}
                </Col>
              </Row>
              <Row className="modalbox-cebox-buttonRow">
                <Col
                  className="modalbox-cebox-button"
                  style={{ margin: "0em 4em" }}
                >
                  {position === "branch admin" ? (
                    <Buttons
                      label={"Delete"}
                      onClick={() => {
                        onDelete(selectValue.id);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col className="modalbox-cebox-button">
                  {position !== "super admin" ? (
                    selectValue.editable ? (
                      <React.Fragment>
                        {FormikForm.renderButton(
                          "Update",
                          isSubmitting,
                          isValid
                        )}
                      </React.Fragment>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ViewTabForm;
