//React element
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import moment from "moment";
//Title
import TitleRow from "../common/titleJob";
import ProcessRow from "../common/titleProcess";

//Api integration
import renderService from "../services/render-service";

//Elements
import TextElement from "./../common/elements/text";
import SignElement from "./../common/elements/status";
import JobListTime from "../common/jobListTimeline";
import SvgGear from "./../../assets/gear.svg";

//Editable icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePen } from "@fortawesome/free-solid-svg-icons";

//View form
import ViewTabForm from "../common/editProcessTabViewForm";

const Archives = ({
  setExportData,
  quickSearch,
  branchNaming,
}) => {
  const [joblistTime, setJoblistTime] = useState("");
  const [jobList, setJobList] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [exportID, setExportID] = useState([]);
  const [totalListLength, setTotalListLength] = useState(0);
  const [totalID, setTotalID] = useState({});
  const position = localStorage.getItem("position");
  const [showEmpty, setShowEmpty] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [tasksPerPage] = useState(15);
  const prevPage = localStorage.getItem("prevPath")
  const [prevActivePage,setPrevActivePage]= useState(parseInt(localStorage.getItem("prevActivePage"))) 


  // console.log("JobList",jobList)
  const getJobList = async () => {
    setJobList([]);
    let start = 0

    if(prevPage != null){
      console.log("123")
      start = prevActivePage * 15
    }

    const response = await renderService.archiveListing(
      quickSearch,
      branchNaming,
      start
    );

    if (response) {

      let tempJob = response.data.aaData.map((listing) => {
        return { ...listing, isChecked: false };
      });

      let tempID = response.data.aaData.map((listing) => {
        return listing.id;
      });

      setJobList(tempJob);
      setExportID(tempID);
      if (quickSearch) {
        setPageCount(Math.ceil(response.data.iTotalRecords / tasksPerPage));
      } else {
        setPageCount(Math.ceil(response.data.iTotalRecords / tasksPerPage));
      }
      setTotalListLength(response.data.iTotalRecords);
      if (response.data.aaData.length === 0) {
        setShowEmpty(true);
      }
    } else {
      setShowEmpty(true);
    }
  };

  const customClose = () => {
    setShow(false);
    setDetailData({});
  };

  const getDetailData = async (id) => {
    const response = await renderService.jobInfo(id);
    setDetailData(response.data);
  };

  const getTotalJobList = async () => {
    const response = await renderService.totalJobListing2();
  };

  const pageChanges = async (data) => {
    // setJobList([])
    localStorage.setItem("prevActivePage",data.selected)
    
    let currentPage = data.selected * 15;

    const nextPage = await renderService.nextJobListing2(
      quickSearch,
      currentPage
    );

    let tempID = nextPage.data.aaData.map((listing) => {
      return listing.id;
    });
    setJobList(nextPage.data.aaData);
    setExportID(tempID);
  };

  useEffect(() => {
    getJobList()
    setPrevActivePage(localStorage.getItem("prevActivePage"))
  }, [detailData, quickSearch, branchNaming]);

  useEffect(() => {}, [showEmpty]);

  const handleChange = (e) => {
    const { id, checked } = e.target;
    if (id === "allSelect") {
      let tempUser = jobList.map((listing) => {
        return { ...listing, isChecked: checked };
      });
      setJobList(tempUser);
    } else {
      let tempP = jobList.map((listing) =>
        listing.id == id ? { ...listing, isChecked: checked } : listing
      );
      setJobList(tempP);
    }
  };

  useEffect(()=>{
    if(prevPage !== null){
      localStorage.removeItem("prevPath")
    }

    return () => {
      // localStorage.setItem("prevPath",window.location.pathname)
      // console.log("prevPath",prevPage)
      // console.log("prevPath local",localStorage.getItem("prevPath"))
      // localStorage.removeItem("prevPath")
      if(window.location.pathname !== "/edit"){
        localStorage.removeItem("prevActivePage")
      }
    }
  },[])

  useEffect(() => {
    // console.log("exportID",exportID)
    if(exportID && isCheck === false){
      setExportData(exportID);
    }
  }, [exportID,isCheck]);

  useEffect(() => {
    setExportData(totalID);
  }, [totalID]);

    return (
    <Container fluid className="body-main">
      {!_.isEmpty(detailData) ? (
        <ViewTabForm
          show={show}
          closeHandler={customClose}
          customerID={_.get(detailData, "id")}
          selectValue={detailData}
        />
      ) : (
        ""
      )}

      <Row className="body-titleRow">
        <Col className="col-6" style={{ alignSelf: "center" }}>
          <TitleRow
            isCheck={isCheck}
            setIsCheck={setIsCheck}
            listLength={totalListLength}
            setTotalID={setTotalID}
            pageName="archive"
            setExportData={setExportData}
          />
        </Col>
        <Col className="col-6">
          <ProcessRow />
        </Col>
      </Row>
      {!_.isEmpty(jobList) ? (
        jobList.length > 0 &&
        jobList.map((listing, count = []) => {
          return (
            <div key={listing.id}>
              <Row className="body-joblistRow">
                {listing.job_status === "Urgent" ? (
                  <React.Fragment>
                    <Col className="d-flex textAlign-center">
                      <Col
                        className="width8"
                        // style={{ backgroundColor: "#2A2B2C" }}
                      >
                        <FontAwesomeIcon
                          id={listing.id}
                          icon={faSquarePen}
                          className="icon-squarePen"
                          onClick={() => {
                            setShow(true);
                            getDetailData(listing.id);
                          }}
                        />
                      </Col>
                      <Col style={{ display: "flex" , background: "#EF7139", borderRadius: "5px" }}>
                      {/* Additional column for super admin */}
                      {position === "super admin" ? (
                        <Col className="width8">
                          <TextElement name={listing.branch} />
                        </Col>
                      ) : (
                        ""
                      )}
                      {/*  */}
                      <Col
                        className="width12"
                        onClick={() => {
                          navigate("/edit", {
                            state: { custData: listing.id },
                          });
                        }}
                      >
                        <TextElement name={listing.job_number} />
                      </Col>
                      <Col className="width17">
                        <TextElement name={moment(listing.start_date).format("yyyy-MM-DD")} />
                      </Col>
                      {/* Switch when super admin is ready */}
                      {position !== "super admin" ? (
                        <Col className="width40">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      ) : (
                        <Col className="width32">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      )}
                      {/* <Col className="col-4 ">
                        <TextElement
                          name={listing.customer_name.substring(0, 18)}
                        />
                      </Col> */}
                      {/*  */}
                      <Col className="width9">
                        <TextElement name={listing.job_status} />
                      </Col>
                      <Col className="width14">
                        <TextElement name={listing.follow_up_by} />
                      </Col>
                      </Col>
                    </Col>
                    <Col
                      className="d-flex textAlign-center elements-text-danger"
                      onClick={() => {
                        setJoblistTime(listing.id);
                      }}
                    >
                      <Col className="col-1">
                        <SignElement
                          value={listing.dismantle_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.quotation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.pretest_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.customer_confirmation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.purchasing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.job_in_progress_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.qc_inspection_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.report_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.billing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.delivery_status}
                          status={listing.job_status}
                        />
                      </Col>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Col className="d-flex textAlign-center elements-text-safe">
                      <Col
                        className="width8"
                        style={{ backgroundColor: "#2A2B2C" }}
                      >
                        <FontAwesomeIcon
                          id={listing.id}
                          icon={faSquarePen}
                          className="icon-squarePen"
                          onClick={() => {
                            setShow(true);
                            getDetailData(listing.id);
                          }}
                        />
                      </Col>
                      <Col style={{ display: "flex" , background: "none", borderRadius: "5px" }}>
                      {position === "super admin" ? (
                        <Col className="width8">
                          <TextElement name={listing.branch} />
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col
                        className="width12"
                        onClick={() => {
                          navigate("/edit", {
                            state: { custData: listing.id },
                          });
                        }}
                      >
                        <TextElement name={listing.job_number} />
                      </Col>
                      <Col className="width17">
                        <TextElement name={moment(listing.start_date).format("yyyy-MM-DD")} />
                      </Col>
                      {position !== "super admin" ? (
                        <Col className="width40">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      ) : (
                        <Col className="width32">
                          <TextElement name={listing.customer_name} />
                        </Col>
                      )}
                      <Col className="width9">
                        <TextElement name={listing.job_status} />
                      </Col>
                      <Col className="width14">
                        <TextElement name={listing.follow_up_by} />
                      </Col>
                      </Col>
                    </Col>
                    <Col
                      className="d-flex textAlign-center elements-text-safe"
                      onClick={() => {
                        setJoblistTime(listing.id);
                      }}
                    >
                      <Col className="col-1">
                        <SignElement
                          value={listing.dismantle_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.quotation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.pretest_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.customer_confirmation_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.purchasing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.job_in_progress_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.qc_inspection_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.report_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.billing_status}
                          status={listing.job_status}
                        />
                      </Col>
                      <Col>
                        <SignElement
                          value={listing.delivery_status}
                          status={listing.job_status}
                        />
                      </Col>
                    </Col>
                  </React.Fragment>
                )}
                {joblistTime == listing.id && (
                  <JobListTime
                    data={jobList}
                    keyID={listing.id}
                    index={count}
                  />
                )}
              </Row>
            </div>
          );
        })
      ) : showEmpty ? (
        <span className="empty-page">No Data Available</span>
      ) : (
        <React.Fragment>
          <img src={SvgGear} className="loading-gear" />
        </React.Fragment>
      )}
      <ReactPaginate
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={pageChanges}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        initialPage={prevActivePage}
      />
    </Container>
  );
};

export default Archives;
