//React element
import React, { useState, useEffect } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
import moment from "moment";

//Form element
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import FormikForm from "./formikForm";
import SelectV4 from "./elements/selectV4";
import SelectV5 from "./elements/selectSearch";

//Elements
import Buttons from "./elements/plainButton";
import SvgImage from "../../assets/x-circle.svg";

//Pop up
import RUsure from "./popup/ruSureBox";

//Api integration
import authService from "../services/auth-service";
import renderService from "../services/render-service";

const AddNewJob = ({ show, closeHandler, jobNo }) => {
  const [dateTime, setDateTime] = useState(new Date());
  const [showBox, setShowBox] = useState(false);
  // const [confirmState, setConfirmState] = useState(false);
  // const [rUsure, setRUsure] = useState({});
  const [optionsCS, setOptionsCS] = useState([]);
  const [optionsApparatus, setOptionsApparatus] = useState({});
  const [optionsPowerUnit, setOptionsPowerUnit] = useState({});
  const [optionsPIC, setOptionsPIC] = useState({});

  async function onSubmit(values, setSubmitting) {
    try {
      const response = await authService.createJob(
        values.customer,
        moment(values.pickDate).format("YYYY-MM-DD h:mm:ss"),
        values.status,
        values.apparatus,
        values.rate,
        values.power,
        values.pic,
        values.gate,
        values.remark
      );
      if (response) {
        window.location.reload();
        // toast.configure();
        // toast("Success", {
        //   type: "success",
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   theme: "dark",
        //   draggableDirection: "x",
        // });
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const error = ex.response.values;
        toast.configure();
        toast(ex.response.data.message, {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
      setSubmitting(false);
    }
  }

  const matchApparatus = (e) => {
    getOptionsPowerUnit(e);
  };

  // const customClose = () => {
  //   setShowBox(false);
  // };

  const getOptionsCS = async () => {
    const response = await renderService.optionsCustomer();
    setOptionsCS(response.data.data);
  };

  const getOptionsApparatus = async () => {
    const response = await renderService.optionsApparatus();
    setOptionsApparatus(response.data.data);
  };

  const getOptionsPowerUnit = async (apparatusID) => {
    const response = await renderService.optionsPowerUnit(apparatusID);
    setOptionsPowerUnit(response.data);
  };

  const getOptionsPIC = async () => {
    const response = await renderService.optionsPICreceive();
    setOptionsPIC(response.data.data);
  };

  useEffect(() => {
    if (show) {
      getOptionsCS().catch((e) => {
        // console.log(e);
      });
      getOptionsApparatus().catch((e) => {
        // console.log(e);
      });
      getOptionsPIC().catch((e) => {
        // console.log(e);
      });
    }

    if (showBox) {
      setShowBox(true);
    }
    // if (confirmState === true) {
    //   onSubmit(rUsure);
    // }
  }, [showBox, show]);

  const statusOptions = [
    { name: "Please Select-", id: 0 },
    { name: "Normal", id: "normal" },
    { name: "Urgent", id: "urgent" },
  ];

  const schema = Yup.object({
    customer: Yup.string().required("Required!"),
    status: Yup.string().required("Required!"),
    apparatus: Yup.string().required("Required!"),
    rate: Yup.string().required("Required!"),
    power: Yup.string().required("Required!"),
    pic: Yup.string().required("Required!"),
  });

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        <img
          src={SvgImage}
          variant="default"
          size="sm"
          onClick={() => closeHandler()}
        />
      </div>
      <Modal.Header
        className="modalbox-header-createdit"
        style={{ margin: "0em 19em" }}
      >
        <Modal.Title>Add New Job</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalbox-body">
        <Container>
          {/* <RUsure
            show={showBox}
            setConfirmState={setConfirmState}
            closeHandler={customClose}
          /> */}
          <Formik
            initialValues={{
              nextJob: jobNo,
              pickDate: new Date(),
              customer: "",
              status: "",
              apparatus: "",
              rate: "",
              power: "",
              pic: "",
              gate: "",
              remark: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values, setSubmitting);
              // setShowBox(true);
              // setRUsure(values);
            }}
          >
            {({
              isSubmitting,
              handleReset,
              setFieldValue,
              isValid,
              errors,
            }) => (
              <Form>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Next Job No :
                  </Col>
                  <Col className="col-7 modalbox-cebox-list">
                    <Field
                      type="text"
                      name="nextJob"
                      className="modalbox-cebox-nextJobNo"
                      values={jobNo}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">Date :</Col>
                  <Col className="col-7">
                    <DateTimePicker
                      onChange={(e) => {
                        {
                          setFieldValue("pickDate", e);
                          setDateTime(e);
                        }
                      }}
                      value={dateTime}
                      name="pickDate"
                      className="modalbox-jobox-datetime color-black"
                      format="yyyy-MM-dd hh:mm:ss"
                      clearIcon={null}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Company Name :
                  </Col>
                  <Col className="col-7 editprocessTabEditForm-title">
                    {/* {FormikForm.renderSelectV2("customer", optionsCS, errors)} */}
                    <SelectV5
                      name={"customer"}
                      options={optionsCS}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">Status :</Col>
                  <Col className="col-7 editprocessTabEditForm-title">
                    {FormikForm.renderSelect("status", statusOptions, errors)}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Apparatus Type :
                  </Col>
                  <Col className="col-7 editprocessTabEditForm-title">
                    <SelectV4
                      name={"apparatus"}
                      options={optionsApparatus}
                      onchange={(e) => {
                        setFieldValue("apparatus", e.target.value);
                        matchApparatus(e.target.value);
                      }}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Rated Unit :
                  </Col>
                  <Col className="col-7 modalbox-cebox-list">
                    {FormikForm.renderPlainText("rate", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Power Unit :
                  </Col>
                  <Col className="col-7 editprocessTabEditForm-title">
                    {FormikForm.renderSelectV2(
                      "power",
                      optionsPowerUnit,
                      errors
                    )}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    PIC Receive :
                  </Col>
                  <Col className="col-7 editprocessTabEditForm-title">
                    {FormikForm.renderSelectV2("pic", optionsPIC, errors)}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">
                    Gate Pass No :
                  </Col>
                  <Col className="col-7 modalbox-cebox-list">
                    {FormikForm.renderPlainText("gate", "", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-jobox-row">
                  <Col className="col-5 modalbox-cebox-listName">Remark :</Col>
                  <Col className="col-7 editprocessTabEditForm-title ">
                    {FormikForm.renderTextarea("remark", errors)}
                  </Col>
                </Row>
                <Row className="modalbox-cebox-buttonRow">
                  <Col
                    className="modalbox-cebox-button"
                    style={{ margin: "0em 4em" }}
                  >
                    <Buttons
                      label={"Clear"}
                      onClick={() => {
                        handleReset();
                      }}
                    />
                  </Col>
                  <Col className="modalbox-cebox-button">
                    {FormikForm.renderButton("Submit", isSubmitting, isValid)}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewJob;
