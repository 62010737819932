//React element
import React, { useState, Suspense, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";

//Elements
import Text from "./elements/titleText";
import SvgGear from "./../../assets/gear.svg";
import ViewBox from "./settingListingViewModal";

//Data template(Support)
import SettingData from "./../dataTemplate/setting-list.json";

//API integration
import renderService from "./../services/render-service.jsx";

//Editable icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const SettingList = () => {
  const [settingData] = useState(SettingData);
  const [showView, setShowView] = useState(false);
  const [modalType, setModalType] = useState("");
  const [viewModalHeader, setViewModalHeader] = useState({});
  const [addModalHeader, setAddModalHeader] = useState({});
  // const ViewBox = React.lazy(() => import("./settingListingViewModal"));
  const [listContent, setListContent] = useState({});
  const position = localStorage.getItem("position");

  const customClose = () => {
    setShowView(false);
  };

  const getListContent = async () => {
    const response = await renderService.settingListContent();

    setListContent(response.data);
  };

  useEffect(() => {
    getListContent().catch((e) => {
      // console.log(e);
    });
  }, []);

  return (
    <Container fluid>
      {/* <Suspense fallback={<React.Fragment />}> */}
      <ViewBox
        show={showView}
        closeHandler={customClose}
        modalType={modalType}
        viewModalHeader={viewModalHeader}
        addModalHeader={addModalHeader}
      />
      {/* </Suspense> */}

      {!_.isEmpty(listContent) ? (
        position !== "workshop" ? (
          <Row>
            <Col>
              {settingData.data.length > 0 &&
                settingData.data.map((listing, index) => {
                  if (listing.list_id >= 0 && listing.list_id <= 10) {
                    if (listing.borderColor === "green") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowGreen" 
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                              {/* <input
                              type="button"
                              className="form-check-input"
                              onClick={() => {
                                setShowView(true);
                                setModalType(listing.type);
                                setViewModalHeader(listing.viewList_header);
                                setAddModalHeader(listing.addList_header);
                              }}
                            /> */}
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    } else if (listing.borderColor === "blue") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowBlue"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >

                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                })}
            </Col>
            <Col>
              {settingData.data.length > 0 &&
                settingData.data.map((listing, index) => {
                  if (listing.list_id >= 11 && listing.list_id <= 18) {
                    if (listing.borderColor === "blue") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowBlue"
                              onClick={() => {
                                setShowView(true);
                                setModalType(listing.type);
                                setViewModalHeader(listing.viewList_header);
                                setAddModalHeader(listing.addList_header);
                              }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    } else if (listing.borderColor === "yellow") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowYellow"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    } else if (listing.borderColor === "red") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowRed"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                })}
            </Col>
            <Col>
              {settingData.data.length > 0 &&
                settingData.data.map((listing, index) => {
                  if (listing.list_id >= 19 && listing.list_id <= 28) {
                    if (listing.borderColor === "blue") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowBlue"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    } else if (listing.borderColor === "yellow") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowYellow"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    } else if (listing.borderColor === "red") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowRed"
                            onClick={() => {
                              setShowView(true);
                              setModalType(listing.type);
                              setViewModalHeader(listing.viewList_header);
                              setAddModalHeader(listing.addList_header);
                            }}
                          >
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                                onClick={() => {
                                  setShowView(true);
                                  setModalType(listing.type);
                                  setViewModalHeader(listing.viewList_header);
                                  setAddModalHeader(listing.addList_header);
                                }}
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                })}
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              {settingData.data.length > 0 &&
                settingData.data.map((listing, index) => {
                  if (listing.list_id == 4) {
                    if (listing.borderColor === "green") {
                      return (
                        <div key={listing.list_id}>
                          <Row className="setting-listingRowGreen">
                            <Col
                              className="col-1 alignSelf-center"
                              style={{ paddingRight: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className="icon-squareToPen"
                                onClick={() => {
                                  setShowView(true);
                                  setModalType(listing.type);
                                  setViewModalHeader(listing.viewList_header);
                                  setAddModalHeader(listing.addList_header);
                                }}
                              />
                            </Col>
                            <Col style={{ color: "white" }}>
                              <Row className="setting-listing-text1">
                                <Text title={listContent[index].label} />
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  }
                })}
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
        )
      ) : (
        <React.Fragment>
          <img src={SvgGear} className="loading-gear" />
        </React.Fragment>
      )}
    </Container>
  );
};

export default SettingList;

// if (listing.borderColor === "green") {
//   return (
//     <div key={listing.list_id}>
//       <Row
//         className="setting-listingRowGreen"
//         // style={{ padding: "0em 2em" }}
//       >
//         <Col className="col-1 alignSelf-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//           ></input>
//         </Col>
//         <Col className="col-4" style={{ color: "white" }}>
//           <Row className="setting-listing-text1">
//             <Text title={listContent[index].label} />
//           </Row>
//           <Row className="setting-listing-text2">
//             <Text title={listContent[index].text} />
//           </Row>
//         </Col>
//         <Col className="col-6 setting-listing-col3">
//           <Row>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"Add"}
//                 onClick={() => {
//                   modalCase(1);
//                   setModalId(listing.addList_type);
//                   setModalHeader(listing.addList_header);
//                 }}
//               />
//             </Col>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"View"}
//                 onClick={() => {
//                   modalCase(2);
//                   setModalType(listing.type);
//                   setModalHeader(listing.viewList_header);
//                 }}
//               />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// } else if (listing.borderColor === "blue") {
//   return (
//     <div key={listing.list_id}>
//       <Row
//         className="setting-listingRowBlue"
//         // style={{ padding: "0em 2em" }}
//       >
//         <Col className="col-1 alignSelf-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//           ></input>
//         </Col>
//         <Col className="col-4" style={{ color: "white" }}>
//           <Row className="setting-listing-text1">
//             <Text title={listContent[index].label} />
//           </Row>
//           <Row className="setting-listing-text2">
//             <Text title={listContent[index].text} />
//           </Row>
//         </Col>
//         <Col className="col-6 setting-listing-col3">
//           <Row>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"Add"}
//                 onClick={() => {
//                   modalCase(1);
//                   setModalId(listing.addList_type);
//                   setModalHeader(listing.addList_header);
//                 }}
//               />
//             </Col>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"View"}
//                 onClick={() => {
//                   modalCase(2);
//                   setModalType(listing.type);
//                   setModalHeader(listing.viewList_header);
//                 }}
//               />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// } else if (listing.borderColor === "yellow") {
//   return (
//     <div key={listing.list_id}>
//       <Row
//         className="setting-listingRowYellow"
//         // style={{ padding: "0em 2em" }}
//       >
//         <Col className="col-1 alignSelf-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//           ></input>
//         </Col>
//         <Col className="col-4" style={{ color: "white" }}>
//           <Row className="setting-listing-text1">
//             <Text title={listContent[index].label} />
//           </Row>
//           <Row className="setting-listing-text2">
//             <Text title={listContent[index].text} />
//           </Row>
//         </Col>
//         <Col className="col-6 setting-listing-col3">
//           <Row>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"Add"}
//                 onClick={() => {
//                   modalCase(1);
//                   setModalId(listing.addList_type);
//                   setModalHeader(listing.addList_header);
//                 }}
//               />
//             </Col>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"View"}
//                 onClick={() => {
//                   modalCase(2);
//                   setModalType(listing.type);
//                   setModalHeader(listing.viewList_header);
//                 }}
//               />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// } else if (listing.borderColor === "red") {
//   return (
//     <div key={listing.list_id}>
//       <Row
//         className="setting-listingRowRed"
//         // style={{ padding: "0em 2em" }}
//       >
//         <Col className="col-1 alignSelf-center">
//           <input
//             type="checkbox"
//             className="form-check-input"
//           ></input>
//         </Col>
//         <Col className="col-4" style={{ color: "white" }}>
//           <Row className="setting-listing-text1">
//             <Text title={listContent[index].label} />
//           </Row>
//           <Row className="setting-listing-text2">
//             <Text title={listContent[index].text} />
//           </Row>
//         </Col>
//         <Col className="col-6 setting-listing-col3">
//           <Row>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"Add"}
//                 onClick={() => {
//                   modalCase(1);
//                   setModalId(listing.addList_type);
//                   setModalHeader(listing.addList_header);
//                 }}
//               />
//             </Col>
//             <Col className="col-1 setting-listing-button">
//               <Button
//                 label={"View"}
//                 onClick={() => {
//                   modalCase(2);
//                   setModalType(listing.type);
//                   setModalHeader(listing.viewList_header);
//                 }}
//               />
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// }
