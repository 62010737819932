import React, { useEffect, useState,useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Form element
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikForm from "./../common/formikForm";

//Elements
import Buttons from "./../common/elements/plainButton";

//Config
import { ImageConfig } from "../../config/ImageConfig";

//Svg
import SvgImage from "../../assets/x-circle.svg";
import authService from "../services/auth-service";

//Pop up
import RUsure from "./popup/ruSureBox";

const AddForm = ({
  show,
  closeHandler,
  customerID,
  // setPurchaseList,
  refresh,
  setRefresh,
  refreshPurchaseSave,
  setRefreshPurchaseSave,
}) => {
  const [data, setData] = useState({
    poNo: "",
    itemName: "",
    quantity: "",
    remark: "",
    files: "",
    saveBtn: "",
    saveAddBtn: "",
  });
  const [selectedImage, setSelectedImage] = useState([]);
  const inputRef = useRef(null)
  // const [showBox, setShowBox] = useState(false);
  // const [rUsure, setRUsure] = useState({});

  // console.log("add form setPurchaseList", setPurchaseList);
  // console.log("Add Tab refresh", refresh);

  // console.log("data",data)

  const schema = Yup.object({
    poNo: Yup.string().required("Required!"),
    itemName: Yup.string().required("Required!"),
    quantity: Yup.number()
      .typeError("Must be numeric input")
      .required("Required!"),
    remark: Yup.string().required("Required!"),
  });

  async function onSubmit(values, customerID, setSubmitting,setFieldValue) {
    inputRef.current.value = null
    // console.log("onllloo")
    setFieldValue("files","")
    // let type = "purchasing list";
    // let method = "create";
    const formData = new FormData();

    formData.append("job_id", customerID);
    formData.append("type", "purchasing list");
    formData.append("po_no", values.poNo);
    formData.append("item_name", values.itemName);
    formData.append("quantity", values.quantity);
    formData.append("remark", values.remark);
    if (values.files) {
      formData.append("attachment[0]", values.files);
    }
    formData.append("method", "create");

    if (values.saveBtn === "save-btn") {
      try {
        const response = await authService.addPurchaseProcess(formData);

        if (response) {
          // window.location.reload();
          toast.configure();
          toast("Success", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          setRefreshPurchaseSave(!refreshPurchaseSave);
          closeHandler();
          setSelectedImage([]);
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast(ex.response.data.message, {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
        setSelectedImage([]);
      }
    } else {
      try {
        const response = await authService.addPurchaseProcess(formData);

        if (response) {
          // console.log("I am ", response);
          toast.configure();
          toast("Success", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          // setPurchaseList(true);
          setRefresh(!refresh);
          setSelectedImage([]);
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast(ex.response.data.message, {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
        setSelectedImage([]);
      }
    }
  }

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files);
    }
  };

  // const customClose = () => {
  //   setShowBox(false);
  // };

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        <img
          src={SvgImage}
          variant="default"
          size="sm"
          onClick={() => closeHandler()}
        />
      </div>
      <Modal.Body className="modalbox-body">
        <Container className="purchaseEdit-container">
          {/* <RUsure show={showBox} closeHandler={customClose} value={rUsure} /> */}

          <Formik
            initialValues={data}
            validationSchema={schema}
            onSubmit={(values, { resetForm, setSubmitting,setFieldValue }) => {
              // console.log("submit")
              onSubmit(values, customerID, setSubmitting,setFieldValue);
              resetForm({ values: "" });
              // setFieldValue("files","")
              // setData({
              //   poNo: "",
              //   itemName: "",
              //   quantity: "",
              //   remark: "",
              //   files: "",
              //   saveBtn: "",
              //   saveAddBtn: "",
              // })
            }}
          >
            {({
              isSubmitting,
              handleReset,
              onSubmit,
              isValidating,
              setFieldValue,
              errors,
            }) => (
              <Form >
                <Row className="purchaseEdit-name">Po No</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("poNo", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Item Name</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("itemName", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Quantity</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("quantity", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Remark</Row>
                <Row className="purchaseEdit-input2">
                  {FormikForm.renderTextarea("remark", errors)}
                </Row>
                <Row className="purchaseEdit-name">Attached PDF File</Row>
                <Row className="purchaseEdit-input">
                  <input
                    ref={inputRef}
                    id="photo"
                    name="files"
                    type="file"
                    multiple
                    onChange={(e) => {
                      imageChange(e);
                      setFieldValue("files", e.target.files[0]);
                      // console.log("ppp")
                    }}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                  />
                  {/* {selectedImage[0] ? (
                    selectedImage[0].type === "image/png" ? (
                      <img
                        src={URL.createObjectURL(selectedImage[0])}
                        alt=""
                        className="uploadfile-preview"
                      />
                    ) : selectedImage[0].type === "image/jpeg" ? (
                      <img
                        src={URL.createObjectURL(selectedImage[0])}
                        alt=""
                        className="uploadfile-preview"
                      />
                    ) : (
                      <img
                        src={
                          ImageConfig[selectedImage[0].type.split("/")[1]] ||
                          ImageConfig["default"]
                        }
                        alt=""
                        className="uploadfile-preview"
                      />
                    )
                  ) : (
                    ""
                  )} */}
                </Row>
                <Row className="purchaseEdit-buttonRow">
                  <Col className="blue-btn">
                    {FormikForm.renderSave(
                      "Save",
                      "save-btn",
                      "saveBtn",
                      setFieldValue
                    )}
                  </Col>
                  <Col className="blue-btn2">
                    {FormikForm.renderSave(
                      "Save & Add",
                      "save-add-btn",
                      "saveAddBtn",
                      setFieldValue
                    )}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddForm;
