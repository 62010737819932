//React element
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

//Elements
import SvgGear from "./../../../assets/gear.svg";

const Loader = ({ showLoad }) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowLoad(false);
  //   }, 5000);
  // });

  return (
    <Modal
      show={showLoad}
      backdrop="static"
      keyboard={false}
      size="sm"
      centered
      className="popup-modalbox-gear"
    >
      <Modal.Header className="popup-modalbox-gearHead">
        Please Wait...
      </Modal.Header>
      <Modal.Body className="popup-modalbox-gearBody">
        <img src={SvgGear} className="loading-gear" />
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
