import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

//Form element
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikForm from "./../common/formikForm";

//Elements
import Buttons from "./../common/elements/plainButton";
import ShowFileImg from "./../common/elements/showFileImg";

//Config
import { ImageConfig } from "../../config/ImageConfig";

//Svg
import SvgImage from "../../assets/x-circle.svg";
import authService from "../services/auth-service";

//Pop up
import RUsure from "./popup/ruSureBox";
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons";

//Editable icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const EditForm = ({
  show,
  closeHandler,
  customerID,
  deleteID,
  initValue,
  refreshPurchaseList,
  setRefreshPurchaseList,
  refreshMedia,
  setRefreshMedia,
}) => {
  // const [data, setData] = useState({
  //   poNo: initValue.po_no,
  //   itemName: initValue.item_name,
  //   quantity: initValue.quantity,
  //   remark: initValue.remark,
  //   files: "",
  //   deleteBtn: "",
  // });
  const [selectedImage, setSelectedImage] = useState([]);
  const [uploadFile, setUploadFile] = useState("Occupied");
  // const [showBox, setShowBox] = useState(false);
  // const [rUsure, setRUsure] = useState({});
  // const [confirmState, setConfirmState] = useState(false);

  const schema = Yup.object({
    poNo: Yup.string().required("Required!"),
    itemName: Yup.string().required("Required!"),
    quantity: Yup.string().required("Required!"),
    remark: Yup.string().required("Required!"),
  });

  // if (confirmState === true) {
  //   onSubmit(rUsure, customerID);
  // }

  // console.log("purchase customID", deleteID);

  async function onSubmit(values, customerID, setSubmitting) {
    const formData = new FormData();

    if (values.deleteBtn === "delete-btn") {
      formData.append("job_id", customerID);
      formData.append("type", "purchasing list");
      formData.append("method", "delete");
      formData.append("purchasing_list_id", deleteID);

      try {
        const response = await authService.deletePurchaseProcess(formData);

        if (response) {
          toast.configure();
          toast("Success", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          // window.location.reload();
          setRefreshPurchaseList(!refreshPurchaseList);
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast(ex.response.data.message, {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
    } else {
      formData.append("job_id", customerID);
      formData.append("type", "purchasing list");
      formData.append("po_no", values.poNo);
      formData.append("item_name", values.itemName);
      formData.append("quantity", values.quantity);
      formData.append("remark", values.remark);
      if (values.files) {
        formData.append("attachment[0]", values.files);
      }
      formData.append("method", "update");
      formData.append("purchasing_list_id", deleteID);

      try {
        const response = await authService.updatePurchaseProcess(formData);

        if (response) {
          toast.configure();
          toast("Success", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
          // window.location.reload();
          setRefreshPurchaseList(!refreshPurchaseList);
          closeHandler();
        }
      } catch (ex) {
        if (ex && Object.keys(ex).length > 0) {
          const error = ex.response.values;
          toast.configure();
          toast(ex.response.data.message, {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            draggableDirection: "x",
          });
        }
        setSubmitting(false);
      }
    }
  }

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      Array.from(e.target.files).forEach((file) => {
        setSelectedImage((selectedImage) => [...selectedImage, file]);
      });
    }
  };

  // const customClose = () => {
  //   setShowBox(false);
  // };

  async function deleteMedia(id) {
    try {
      const response = await authService.deleteMedia(id);

      if (response) {
        toast.configure();
        toast("Success delete attachment", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
        setRefreshMedia(!refreshMedia);
      }
    } catch (ex) {
      if (ex && Object.keys(ex).length > 0) {
        const error = ex.response.values;
        toast.configure();
        toast("Fail delete attachment", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "dark",
          draggableDirection: "x",
        });
      }
    }
  }

  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
      contentClassName="popup-modalbox"
    >
      <div className="modalbox-Xbutton-div">
        {/* <Button
          variant="default"
          className="modalbox-Xbutton"
          size="sm"
          onClick={() => closeHandler()}
        >
          X
        </Button> */}
        <img
          src={SvgImage}
          variant="default"
          // className="modalbox-Xbutton"
          size="sm"
          onClick={() => closeHandler()}
        />
      </div>
      <Modal.Body className="modalbox-body">
        <Container className="purchaseEdit-container">
          {/* <RUsure
            show={showBox}
            setConfirmState={setConfirmState}
            closeHandler={customClose}
            value={rUsure}
          /> */}

          <Formik
            initialValues={{
              poNo: initValue.po_no,
              itemName: initValue.item_name,
              quantity: initValue.quantity,
              remark: initValue.remark,
              files: initValue.attachment ? initValue.attachment.url : "",
              deleteBtn: "",
            }}
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values, customerID, setSubmitting);
              // setShowBox(true);
              // setRUsure(values);
            }}
          >
            {({ isSubmitting, isValid, setFieldValue, errors }) => (
              <Form>
                <Row className="purchaseEdit-name">Po No</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("poNo", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Item Name</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("itemName", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Quantity</Row>
                <Row className="purchaseEdit-input">
                  {FormikForm.renderPlainText("quantity", "", errors)}
                </Row>
                <Row className="purchaseEdit-name">Remark</Row>
                <Row className="purchaseEdit-input2">
                  {FormikForm.renderTextarea("remark", errors)}
                </Row>
                <Row className="purchaseEdit-name">Attached PDF File</Row>
                <Row className="purchaseEdit-input">
                  {/* <input
                    id="photo"
                    name="files"
                    type="file"
                    multiple
                    onChange={imageChange}
                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*"
                  />
                  {selectedImage &&
                    selectedImage.map((url) => {
                      if (url.type === "image/png") {
                        return (
                          <img
                            src={URL.createObjectURL(url)}
                            alt=""
                            className="uploadfile-preview"
                          />
                        );
                      } else if (url.type === "image/jpeg") {
                        return (
                          <img
                            src={URL.createObjectURL(url)}
                            alt=""
                            className="uploadfile-preview"
                          />
                        );
                      } else {
                        return (
                          // <div className="uploadfile-preview">
                          <img
                            // src={URL.createObjectURL(url)}
                            src={
                              ImageConfig[url.type.split("/")[1]] ||
                              ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                          // </div>
                        );
                      }
                    })} */}
                  {initValue.attachment[0] ? (
                    uploadFile === "Occupied" ? (
                      <Row>
                        <div className="icon-circleX">
                          <FontAwesomeIcon
                            id={initValue.attachment[0].id}
                            icon={faCircleXmark}
                            onClick={() => {
                              deleteMedia(initValue.attachment[0].id);
                              setUploadFile("");
                              // console.log("12")
                            }}
                          />
                        </div>
                        {/* <img
                          src={initValue.attachment[0].url}
                          alt=""
                          className="uploadfile-preview"
                        /> */}
                        {/* <ShowFileImg data={initValue.attachment[0].url} /> */}
                        <div>{initValue.attachment[0].name}</div>
                      </Row>
                    ) : (
                      <React.Fragment>
                        <input
                          id="photo"
                          name="files"
                          type="file"
                          onChange={(e) => {
                            imageChange(e);
                            setFieldValue("files", e.target.files[0]);
                            // console.log("qwe")
                          }}
                          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                        />
                        {/* {selectedImage[0] ? (
                          selectedImage[0].type === "image/png" ? (
                            <img
                              src={URL.createObjectURL(selectedImage[0])}
                              alt=""
                              className="uploadfile-preview"
                            />
                          ) : selectedImage[0].type === "image/jpeg" ? (
                            <img
                              src={URL.createObjectURL(selectedImage[0])}
                              alt=""
                              className="uploadfile-preview"
                            />
                          ) : (
                            <img
                              src={
                                ImageConfig[
                                  selectedImage[0].type.split("/")[1]
                                ] || ImageConfig["default"]
                              }
                              alt=""
                              className="uploadfile-preview"
                            />
                          )
                        ) : (
                          ""
                        )} */}
                      </React.Fragment>
                    )
                  ) : (
                    <React.Fragment>
                      <input
                        id="photo"
                        name="files"
                        type="file"
                        // multiple
                        onChange={(e) => {
                          imageChange(e);
                          // setUploadFile(e.target.files[0]);
                          // setFieldValue(handleFile);
                          setFieldValue("files", e.target.files[0]);
                          // console.log("onChange event", e.target.files);
                          // console.log("ppp")
                        }}
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, application/csv, image/*"
                      />
                      {/* {selectedImage[0] ? (
                        selectedImage[0].type === "image/png" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : selectedImage[0].type === "image/jpeg" ? (
                          <img
                            src={URL.createObjectURL(selectedImage[0])}
                            alt=""
                            className="uploadfile-preview"
                          />
                        ) : (
                          <img
                            src={
                              ImageConfig[
                                selectedImage[0].type.split("/")[1]
                              ] || ImageConfig["default"]
                            }
                            alt=""
                            className="uploadfile-preview"
                          />
                        )
                      ) : (
                        ""
                      )} */}
                    </React.Fragment>
                  )}
                </Row>
                <Row className="purchaseEdit-buttonRow">
                  <Col className="blue-btn">
                    {FormikForm.renderButton("Save", isSubmitting, isValid)}
                  </Col>
                  <Col className="blue-btn">
                    {FormikForm.renderSave(
                      "Delete",
                      "delete-btn",
                      "deleteBtn",
                      setFieldValue
                    )}
                  </Col>
                  {/* <Col>
                    <Buttons
                      className="normal-btn"
                      label={"Delete"}
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                  </Col> */}
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default EditForm;
