//React element
import React from "react";
import { Field, ErrorMessage } from "formik";
// import Select from "react-select";

const SelectV2 = ({ key, name, options, errors, ...rest }) => {
  return (
    <React.Fragment>
      <Field as="select" name={name} {...rest}>
        <option value="" >Please Select -</option>
        {options.length > 0 &&
          options.map((listing) => {
            return (
              <option value={listing.id} key={listing.id}>
                {/* {listing.name} */}
                {listing.name
                  ? listing.name
                  : listing.label
                  ? listing.label
                  : ""}
              </option>
            );
          })}
      </Field>
      {/* <Select options={options} onChange={onChange} value={value} /> */}
      {/* <ErrorMessage className="form-error" name={name} component="div" /> */}
      {errors[name] ? <div className="form-error">{errors[name]}</div> : ""}
    </React.Fragment>
  );
};

export default SelectV2;
