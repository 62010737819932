//React element
import React, { useState, useEffect, Suspense } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Elements
import BodyTitle from "./elements/titleText";
import CheckboxElement from "./elements/checkbox";
import Loader from "./popup/loader";

//API integration
import renderService from "../services/render-service";

const Title = ({ isCheck,setIsCheck,listLength, setTotalID, pageName, setExportData }) => {
  // console.log("titleJob handler", handleChange);
  // const [isCheck, setIsCheck] = useState(false);
  const [allID, setAllID] = useState([]);
  const [showLoad, setShowLoad] = useState(false);
  const position = localStorage.getItem("position");

  const getAllID = async () => {
    const response = await renderService.listAllID(listLength, pageName);

    // console.log("allID response", response.data);

    let tempID = response.data.aaData.map((listing) => {
      return listing.id;
    });

    setAllID(tempID);
  };

  useEffect(() => {
    if (isCheck === true) {
      getAllID().catch((e) => {
        // console.log(e);
      });
    } else if (isCheck === false) {
      setAllID(0);
    }
  }, []);

  useEffect(() => {
    setTotalID(allID);
  }, [allID]);

  // console.log("isCheck value", isCheck);
  // console.log("listLength value", listLength);
  // console.log("allID value", allID);

  return (
    <Container className="title-padding body-titleRow-text">
      {/* <Loader showLoad={showLoad} setShowLoad={setShowLoad} /> */}

      <Row>
        <Col className="width8" style={{ padding: "0em" }}>
          {/* <CheckboxElement /> */}
          {/* <input
            type="checkbox"
            className="form-check-input"
            id="allSelect"
            checked={
              users.filter((user) => user?.isChecked !== true).length < 1
            }
            checked={!jobList.some((listing) => listing?.isChecked !== true)}
            onChange={handleChange}
            onClick={handleChange}
          /> */}
          <input
            type="checkbox"
            className="form-check-input"
            id="selectAll"
            checked={isCheck}
            onChange={(event) => {
              setIsCheck(!isCheck);
              if (event.target.checked) {
                setExportData("all");
              }
            }}
          />
        </Col>
        <Col style={{ padding: "0em" , display: "flex" , background: "none", borderRadius: "5px" }}>
        {/* Additional column for super admin */}
        {position === "super admin" ? (
          <Col className="width8" style={{ padding: "0em" }}>
            <BodyTitle title="Branch" />
          </Col>
        ) : (
          ""
        )}
        {/*  */}
        <Col className="width12" style={{ padding: "0em" }}>
          <BodyTitle title="Job No." />
        </Col>
        <Col className="width17" style={{ padding: "0em" }}>
          <BodyTitle title="Start Date" />
        </Col>
        {/* Switch when super admin position is ready */}
        {position !== "super admin" ? (
          <Col className="width39" style={{ padding: "0em" }}>
            <BodyTitle title="Company Name" />
          </Col>
        ) : (
          <Col className="width30" style={{ padding: "0em" }}>
            <BodyTitle title="Company Name" />
          </Col>
        )}
        {/* <Col className="col-4">
          <BodyTitle title="Company Name" />
        </Col> */}
        {/*  */}
        <Col className="width9" style={{ padding: "0em" }}>
          <BodyTitle title="Status" />
        </Col>
        <Col className="width15" style={{ paddingLeft: "0em" }}>
          <BodyTitle title="Follow up" />
        </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Title;
