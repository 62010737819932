//React element
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Body
import SettingList from "../common/settingListing";

//Element
import CheckboxElement from "./../common/elements/checkbox";

//Data template(Support)
import SettingData from "./../dataTemplate/setting-list.json";

const Setting = () => {

  return (
    <Container fluid className="body-main">
      <Row className="setting-titleRow">
        <Col className="col-1">
          <input type="checkbox" className="form-check-input"></input>
        </Col>
        <Col>Title</Col>
      </Row>
      <Row>
        <SettingList />
      </Row>
    </Container>
  );
};

export default Setting;
