//React element
import React, { useEffect, useState } from "react";
import { Modal, Col } from "react-bootstrap";
import _ from "lodash";
import { VscDiffAdded } from "react-icons/vsc";
import SelectSearch, { fuzzySearch } from "react-select-search";

//Modal body
import ModalBox from "./settingModalBox";

//Svg
import SvgImage from "../../assets/x-circle.svg";

//Pop up
import AddBox from "./settingListingAddModal";

const ViewModal = ({
  show,
  closeHandler,
  modalType,
  viewModalHeader,
  addModalHeader,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [confirmAdd, setConfirmAdd] = useState();
  const [searchValue, setSearchValue] = useState("");

  const customClose = () => {
    setShowAdd(false);
  };
  
  // console.log("modalType",modalType)
  // console.log("eee",searchValue)
  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setSearchValue("");
  }, [modalType]);

  return (
    <React.Fragment>
      <AddBox
        show={showAdd}
        closeHandler={customClose}
        modalID={modalType}
        modalHead={addModalHeader}
        setConfirmAdd={setConfirmAdd}
      />

      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        contentClassName="popup-modalbox"
      >
        <div className="modalbox-Xbutton-div">
          <img
            src={SvgImage}
            variant="default"
            // className="modalbox-Xbutton"
            size="sm"
            onClick={() => {
              closeHandler();
              // window.location.reload();
            }}
          />
        </div>
        <Modal.Header className="modalbox-header-createdit" style={{padding:"1.5em"}}>
          <Modal.Title>{viewModalHeader}</Modal.Title>
          <Col>
            <VscDiffAdded
              style={{
                color: "#AAA5BE",
                fontSize: "x-large",
                margin: "0em 0.5em",
              }}
              onClick={() => {
                setShowAdd(true);
              }}
            />
          </Col>
          <Col className="search">
          <input
            className="search-input"
            placeholder="Search..."
            value={searchValue}
            onChange={handleChange}
          />
          </Col>
        </Modal.Header>
        <Modal.Body className="modalbox-body">
          {ModalBox.ViewModalBox(modalType, viewModalHeader, confirmAdd,searchValue)}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ViewModal;
