//React element
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import _ from "lodash";
import moment from "moment/moment";
//Elements
import Button from "./elements/plainButton";
import TextElement from "./../common/elements/text";
// import SignElement from "./../common/elements/status";
// import BodyProcess from "./../common/elements/titleText";
// import BodyTitle from "./../common/elements/titleText";

//View form
import ViewTabForm from "./editProcessTabViewForm";

const ViewTab = ({ data, getJobList }) => {
  const [show, setShow] = useState(false);
  const [showviewTabForm, setShowviewTabForm] = useState("viewTabForm");

  // console.log("data ss", data);
  useEffect(() => {
    // console.log("View tab data", data);
  }, []);

  const customClose = () => {
    setShow(false);
  };

  function showContent() {
    return (
      <Container>
        <Row>
          <Col className="editprocessTabView-col1">Job No :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "job_no")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Date :</Col>
          <Col className="editprocessTabView-col2">
            <span>{moment(_.get(data, "created_at")).format('YYYY-MM-DD')}<br />{moment(_.get(data, "created_at")).format('hh:mm:ss')}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Company Name :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "company_name")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Status :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "status")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Apparatus Type :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "apparatus_type_name")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Rated Unit :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "rated_unit")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Power Unit :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "power_unit_name")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">PIC Receive :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "pic_receive_name")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Gate Pass No :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "gate_pass_no")}</span>
          </Col>
        </Row>
        <Row>
          <Col className="editprocessTabView-col1">Follow up by :</Col>
          <Col className="editprocessTabView-col2">
            <span>{_.get(data, "follow_up_by_name")}</span>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container className="editprocessTabcontent-container">
      <ViewTabForm
        show={show}
        setShow={setShow}
        showviewTabForm={showviewTabForm}
        closeHandler={customClose}
        customerID={_.get(data, "id")}
        selectValue={data}
        getJobListEdit={getJobList}
        // refreshPage={refreshPage}
        // setRefreshPage={setRefreshPage}
      />

      {showContent()}
      <Row className="editprocessTabEdit-row1">
        {data.editable === true ? (
          <Button
            label="View"
            onClick={() => {
              setShow(true);
              getJobList();
            }}
          />
        ) : (
          ""
        )}
        {/* <Button
          label="View"
          onClick={() => {
            setShow(true);
          }}
        /> */}
      </Row>
    </Container>
  );
};

export default ViewTab;
