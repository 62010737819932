//React element
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const JoblistTimeline = ({ data, keyID, index }) => {
  return (
    <Container fluid className="body-joblist-timeline">
      <Row id={keyID}>
        <Col />
        <Col className="d-flex body-joblist-timeline-text textAlign-center">
          <Col>
            {data[index].dismantle_time &&
            data[index].dismantle_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].dismantle_time}</div>
                <div>{data[index].dismantle_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            {data[index].quotation_time &&
            data[index].quotation_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].quotation_time}</div>
                <div>{data[index].quotation_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            {data[index].pretest_time && data[index].pretest_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].pretest_time}</div>
                <div>{data[index].pretest_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            {data[index].customer_confirmation_time &&
            data[index].customer_confirmation_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].customer_confirmation_time}</div>
                <div>{data[index].customer_confirmation_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            {data[index].purchasing_time &&
            data[index].purchasing_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].purchasing_time}</div>
                <div>{data[index].purchasing_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col>
            {data[index].job_in_progress_time &&
            data[index].job_in_progress_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].job_in_progress_time}</div>
                <div>{data[index].job_in_progress_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col className="textAlign-center">
            {data[index].qc_inspection_time &&
            data[index].qc_inspection_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].qc_inspection_time}</div>
                <div>{data[index].qc_inspection_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col className="textAlign-center">
            {data[index].report_time && data[index].report_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].report_time}</div>
                <div>{data[index].report_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col className="textAlign-center">
            {data[index].billing_time && data[index].billing_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].billing_time}</div>
                <div>{data[index].billing_date}</div>
              </React.Fragment>
            )}
          </Col>
          <Col className="textAlign-center">
            {data[index].delivery_time && data[index].delivery_date == "--" ? (
              "--"
            ) : (
              <React.Fragment>
                <div>{data[index].delivery_time}</div>
                <div>{data[index].delivery_date}</div>
              </React.Fragment>
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default JoblistTimeline;
