//React element
import React, { useState, useEffect } from "react";
import { Field } from "formik";
import SelectSearch, { fuzzySearch } from "react-select-search";

const SelectSearching = ({
  key,
  name,
  options,
  errors,
  onchange,
  setFieldValue,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <React.Fragment>
      <SelectSearch
        options={options}
        value={searchValue}
        name={name}
        placeholder="Please Select -"
        filterOptions={fuzzySearch}
        search={true}
        emptyMessage="Result not found"
        onChange={(e) => {
          setSearchValue(e);
          setFieldValue("customer", e);
        }}
        onBlur={(e) => {}}
      />
      {errors[name] ? <div className="form-error">{errors[name]}</div> : ""}
    </React.Fragment>
  );
};

export default SelectSearching;
