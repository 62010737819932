function getHeader(headType) {
  switch (headType) {
    case 1: {
      return {
        header: true,
        profileImage: true,
        profileTab: true,
        userName: true,
        userPosition: true,
        pageName: true,
        pageNaming: "Main Page",
        companyNumber: true,
        companyStatus: "Current 500 Companys",
        profileFunctionQS: true,
        profileFunctionA: true,
        profileFunctionE: true,
        profileFunctionS: true,
      };
    }
    case 2: {
      return {
        header: true,
        profileImage: true,
        profileTab: true,
        userName: true,
        userPosition: true,
        pageName: true,
        pageNaming: "Archives Page",
        companyNumber: true,
        companyStatus: "Complete 300 Job",
        profileFunctionQS: true,
        profileFunctionA: false,
        profileFunctionE: true,
        profileFunctionS: true,
      };
    }
    case 3: {
      return {
        header: true,
        profileImage: true,
        profileTab: true,
        userName: true,
        userPosition: true,
        pageName: true,
        pageNaming: "Setting Page",
        companyNumber: true,
        companyStatus: "Last Setting Update: 16:04 04/03/2022",
        profileFunctionQS: true,
        profileFunctionA: false,
        profileFunctionE: false,
        profileFunctionS: false,
      };
    }
    case 4: {
      return {
        header: true,
        profileImage: true,
        profileTab: true,
        userName: true,
        userPosition: true,
        pageName: true,
        pageNaming: "Edit Page",
        companyNumber: true,
        companyStatus: "Current 500 Companys",
        profileFunctionQS: false,
        profileFunctionA: false,
        profileFunctionE: true,
        profileFunctionS: true,
      };
    }

    default: {
      return {
        header: true,
      };
    }
  }
}

function getListButton(buttonType) {
  switch (buttonType) {
    case "delete": {
      return {
        buttonEdit: false,
        buttonDelete: true,
        buttonActive: false,
        buttonInactive: false,
      };
    }
    case "active": {
      return {
        buttonEdit: false,
        buttonDelete: false,
        buttonActive: true,
        buttonInactive: false,
      };
    }
    case "inactive": {
      return {
        buttonEdit: false,
        buttonDelete: false,
        buttonActive: false,
        buttonInactive: true,
      };
    }

    default: {
      return {
        buttonEdit: false,
        buttonDelete: false,
        buttonActive: false,
        buttonInactive: false,
      };
    }
  }
}

export default {
  getHeader,
  getListButton,
};
